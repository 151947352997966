import PropTypes from "prop-types";
import React from "react";
import { IconOverview } from "../IconOverview";
import "./NavsRiskTools.css";
import { NavLink } from "react-router-dom";
import { IconSupport } from "../IconSupport/IconSupport";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useState, useEffect } from "react";

export const NavsRiskTools = ({
  property1,
  style,
  frameStyle,
  overviewStyle,
  medicalhistorytab,
  labhistorytab,
  casehistorytab,
}) => {
      const [familyInfo, setFamilyInfo] = useState(null);
      useEffect(() => {
        AxiosInstance.get("profile/get")
          .then((response) => {
            console.log("response: ", response);
            setFamilyInfo(response?.profile);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);
  return (
    <>
      {(familyInfo?.age >= 3 || familyInfo == undefined || familyInfo?.age == null) && (
    <div className={`navs-NavsRiskTools ${property1}`} style={style}>
      <div className="frame" style={frameStyle}>
        <div className="frame-wrapper">
          <div className="div">
            {property1 === "default" && <IconSupport></IconSupport>}

            {property1 === "variant-2" && <IconSupport></IconSupport>}

            <div className="component-8">
              <div className="menu-item-8">
                <div className="frame-49">
                  <div className="frame-50">
                    <div className="frame-51">
                      <NavLink to="/risk-tools" className="text-wrapper-106 menu-item overview">
                        Risk Tools
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

          {/* <>
        <div className="component-5">
          <div className="menu-item-5">
            <div className="frame-49">
              <div className="frame-50">
                <div className="frame-51">
                  <div className="ellipse-15" />
                  <NavLink
                    to="/lifestyle-risk-assessment"
                    className="text-wrapper-106 menu-item"
                    style={{ color: labhistorytab }}
                  >
                    Lifestyle
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="component-6">
          <div className="menu-item-6">
            <div className="frame-49">
              <div className="frame-52">
                <div className="frame-51">
                  <div className="ellipse-15" />
                  <NavLink
                    to="/risk-assessment/diabetes-calculator"
                    className="text-wrapper-106 menu-item"
                    style={{ color: casehistorytab }}
                  >
                    Diabetes
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="component-7">
          <div className="menu-item-7">
            <div className="frame-49">
              <div className="frame-50">
                <div className="frame-51">
                  <div className="ellipse-15" />
                  <NavLink
                    to="/kidney-risk-assessment"
                    className="text-wrapper-106 menu-item"
                    style={{ color: medicalhistorytab }}
                  >
                    Kidney
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="component-6">
          <div className="menu-item-6">
            <div className="frame-49">
              <div className="frame-52">
                <div className="frame-51">
                  <div className="ellipse-15" />
                  <NavLink
                    to="/cardiac-risk-assessment"
                    className="text-wrapper-106 menu-item"
                    style={{ color: casehistorytab }}
                  >
                    Cardiac
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> */}
        </div>
      )}
    </>
  );
};

NavsRiskTools.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
