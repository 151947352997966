import { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  FormControl,
  RadioGroup,
} from "reactstrap";
import { Select, FormControlLabel, Checkbox, Grid, FormControl, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const DieticianGeneralInfo = () => {
  const [generalInfoData, setGeneralInfoData] = useState([]);
 const [firstName, setFirstName] = useState("");
 const [lastName, setLastName] = useState("");
 const [email, setEmail] = useState("");
 const [phoneNumber, setPhoneNumber] = useState("");
 const [registeredBusiness, setRegisteredBusiness] = useState(false);
 const [organizationName, setOrganizationName] = useState("");
 const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState("");
 const [organizationBusinessTime, setOrganizationBusinessTime] = useState("");
 const [organizationAddress, setOrganizationAddress] = useState("");
 const [dietitianDetail, setDietitianDetail] = useState("");
 const [educationQualifications, setEducationQualifications] = useState("");
 const [yearsWorkingAsDietitian, setYearsWorkingAsDietitian] = useState();
 const [everWorkedAsClinicalDietitian, seteverWorkedAsClinicalDietitian] = useState(false);
 const [hospitalName, setHospitalName] = useState("");
 const [state, setState] = useState("");
 const [city, setCity] = useState("");
 const [yearsWorkingAtHospital, setYearsWorkingAtHospital] = useState("");
 const [specialization, setSpecialization] = useState("");
 const [nutritionConsultingForDiseasesForClients, setNutritionConsultingForDiseasesForClients] = useState("");
 const [historyOfWorkingWithChild, setHistoryOfWorkingWithChild] = useState("");
 const [detailsOfMethodologyOrDietaryGuidelinesFollowed, setDetailsOfMethodologyOrDietaryGuidelinesFollowed] = useState("");
 const [genderPreference, setGenderPreference] = useState("");
 const [childrenFromBirthTillThreeYearsAge, setChildrenFromBirthTillThreeYearsAge] = useState("");
 const [childrenFromTwoToTwelveYearsAge, setChildrenFromTwoToTwelveYearsAge] = useState("");
 const [childrenFromThirteenToTwentyYearsAge, setChildrenFromThirteenToTwentyYearsAge] = useState("");
 const [adultMaleFromTwentyOneToSixtyYearsAge, setAdultMaleFromTwentyOneToSixtyYearsAge] = useState("");
 const [adultFemaleFromTwentyOneToSixtyYearsAge, setAdultFemaleFromTwentyOneToSixtyYearsAgee] = useState("");
 const [seniorsOfAgeGreaterThenSixty, setSeniorsOfAgeGreaterThenSixty] = useState("");
 const [sharingReferences, setSharingReferences] = useState(false);
  const [diseasesOtherValue, setDiseasesOtherValue] = useState("")
  const [formData, setFormData] = useState({
      "CVD": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      "Hypertension": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      "Obesity/Weight Loss": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      Diabetes: {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      "Colorectal cancer": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      "Prostate cancer": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      "Cervical cancer": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
      "Oral cancer": {
        gender: "",
        ageGroup: [],
        years: "",
        patientsHelped: "",
      },
  });
  
  const [referenceDetail, setReferenceDetail] = useState({
    "reference1": {
      name: "",
      mobile: "",
    },
    "reference2": {
      name: "",
      mobile: "",
    },
      "reference3": {
      name: "",
      mobile:"",
  }
  });

  const [preferredDayAndTime, setPreferredDayAndTime] = useState({
    Weekdays: "",
    time:""
  });
 
  const [files, setFiles] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [labPartnerCertificate, setLabPartnerCertificate] = useState([]);
  const [ISOreports, setISOReports] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [showRegisteredOrganization, setShowRegisteredOrganization] = useState();
  const [showEverWorkedasClicnicalAdvisor, setShowEverWorkedasClicnicalAdvisor] = useState()
  const [showReferences, setShowReferences] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleDietitianDetail = (event) => {
    setDietitianDetail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      dietitianDetail: false,
    }));
  };

  const handleEducationQualification = (event) => {
    setEducationQualifications(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      educationQualifications: false,
    }));
  };

  const handleYearsWorkingAsDietitian = (event) => {
    setYearsWorkingAsDietitian(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearsWorkingAsDietitian: false,
    }));
  };

  const handleNutritionConsultingForDiseasesForClients = (event) => {
    const value = event.target.value;

    setNutritionConsultingForDiseasesForClients((prevString) => {
      const currentValues = prevString ? prevString.split(", ") : [];
      if (currentValues.includes(value)) {
        const updatedValues = currentValues.filter((member) => member !== value);
        return updatedValues.join(", ");
      } else {
        currentValues.push(value);
        return currentValues.join(", ");
      }
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      nutritionConsultingForDiseasesForClients: false,
    }));
  };
    const handleOtherDiseases = () => {
      const trimmedValue = diseasesOtherValue.trim();
      if (trimmedValue !== "") {
        const otherValue = trimmedValue;
        setNutritionConsultingForDiseasesForClients((prevString) => {
          const currentValues = prevString ? prevString.split(", ") : [];
          if (!currentValues.includes(otherValue)) {
            currentValues.push(otherValue);
          }
          return currentValues.join(", ");
        });
      }
    };
  
  const handleDiseasesOtherValue = (event) => {
    setDiseasesOtherValue(event.target.value);
  }

  const handleHistoryOfWorkingWithChild = (event) => {
    setHistoryOfWorkingWithChild(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      historyOfWorkingWithChild: false,
    }));
  };

  const handleDetailsOfMethodologyOrDietaryGuidelinesFollowed = (event) => {
    setDetailsOfMethodologyOrDietaryGuidelinesFollowed(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      detailsOfMethodologyOrDietaryGuidelinesFollowed: false,
    }));
  };

  const handleGenderPreference = (event) => {
    setGenderPreference(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      genderPreference: false,
      }));
  }

    const handleGenderChange = (disease, gender) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [disease]: {
          ...(prevFormData?.[disease] ?? {}),
          gender,
        },
      }));
    };

    const handleAgeGroupChange = (disease, ageGroup) => {
      setFormData((prevFormData) => {
        const existingAgeGroups = prevFormData?.[disease]?.ageGroup ?? [];
        const newAgeGroups = existingAgeGroups.includes(ageGroup) ? existingAgeGroups.filter((group) => group !== ageGroup) : [...existingAgeGroups,    ageGroup];
      
        return {
        ...prevFormData,
        [disease]: {
          ...(prevFormData?.[disease] ?? {}),
          ageGroup: newAgeGroups,
        },
        };
      });
    };
  
 const handleYearsChange = (disease, years) => {
   setFormData((prevFormData) => ({
     ...prevFormData,
     [disease]: {
       ...(prevFormData?.[disease] ?? {}),
       years,
     },
   }));
 };

    const handlePatientsHelpedChange = (disease, patientsHelped) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [disease]: {
          ...(prevFormData?.[disease] ?? {}),
          patientsHelped,
        },
      }));
    };
  
 const handleWeekDays = (name, value) => {
   setPreferredDayAndTime((prevState) => ({
     ...prevState,
     Weekdays: value,
   }));
 };

 const handleTime = (name, value) => {
   setPreferredDayAndTime((prevState) => ({
     ...prevState,
     time: value,
   }));
 };

  
const handleReferenceUpdate = (reference, field, value) => {
  setReferenceDetail((prevReferences) => ({
    ...prevReferences,
    [reference]: {
      ...(prevReferences?.[reference] ?? {}),
      [field]: value,
    },
  }));
};

  const handleOrganizationName = (event) => {
    setOrganizationName(event.target.value);
  };
  const handleOrganizationPhoneNumber = (event) => {
    const phoneNumber = event.target.value;
    setOrganizationPhoneNumber(phoneNumber);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationPhoneNumber: phoneNumber.length > 10,
    }));
  };
  
   const handleOrganizationBusinessTime = (event) => {
     setOrganizationBusinessTime(event.target.value);
   };
   const handleOrganizationAddress = (event) => {
     setOrganizationAddress(event.target.value);
   };
   const handleHospitalName = (event) => {
     setHospitalName(event.target.value);
   };
     const handleState = (event) => {
       setState(event.target.value);
     };
     const handleCity = (event) => {
       setCity(event.target.value);
     };
     const handleHowManyYears = (event) => {
       setYearsWorkingAtHospital(event.target.value);
     };
     const handleSpecialization = (event) => {
       setSpecialization(event.target.value);
     };
  
  console.log("orani", organizationName)
const yearsWorkingAsDietitianInt = parseInt(yearsWorkingAsDietitian, 10);
  const handleSubmit = async(e) => {
    e.preventDefault();
    let error = {};
    if (registeredBusiness == null || registeredBusiness == "") {
      error.registeredBusiness = true
    }
    if (registeredBusiness == true) {
      if (organizationName == null || organizationName == "") {
        error.organizationName = true;
      }
      if (organizationPhoneNumber == null || organizationPhoneNumber == "" ) {
        error.organizationPhoneNumber = true
      }
      if (organizationPhoneNumber != null) {
        if (organizationPhoneNumber.length > 10) {
          error.organizationPhoneNumber = true
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter Valid Phone Number",
          });
        }
      }
      if (organizationBusinessTime == null || organizationBusinessTime == "") {
        error.organizationBusinessTime = true
      }
      if (organizationAddress == null || organizationAddress == "") {
        error.organizationAddress = true;
      }
    }
    if (dietitianDetail == null || dietitianDetail == "") {
      error.dietitianDetail = true
    }
    if (educationQualifications == null || educationQualifications == "") {
      error.educationQualifications = true
    }
    if (yearsWorkingAsDietitian == null || yearsWorkingAsDietitian == "") {
      error.yearsWorkingAsDietitian = true;
    }
    if (everWorkedAsClinicalDietitian == null || everWorkedAsClinicalDietitian == "") {
      error.everWorkedAsClinicalDietitian = true;
    }
    if (everWorkedAsClinicalDietitian == true || everWorkedAsClinicalDietitian == "") {
      if (hospitalName == null || hospitalName == "") {
        error.hospitalName = true;
      }
      if (state == null || state == "") {
        error.state = true;
      }
      if (city == null || city == "") {
        error.city = true;
      }
      if (yearsWorkingAtHospital == null || yearsWorkingAtHospital == "") {
        error.yearsWorkingAtHospital = true;
      }
      if (specialization == null || specialization == "") {
        error.specialization = true;
      }
    }
    if (historyOfWorkingWithChild == null || historyOfWorkingWithChild == "") {
      error.historyOfWorkingWithChild = true;
    }
    if (detailsOfMethodologyOrDietaryGuidelinesFollowed == null || detailsOfMethodologyOrDietaryGuidelinesFollowed == "") {
      error.detailsOfMethodologyOrDietaryGuidelinesFollowed = true;
    }
    if (genderPreference == null || genderPreference == "") {
      error.genderPreference = true;
    }
    if (sharingReferences == null || sharingReferences == "") {
      error.sharingReferences = true;
    }
    if (preferredDayAndTime == null || preferredDayAndTime == "") {
      error.preferredDayAndTime = true;
    }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      if (organizationPhoneNumber.length > 10) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter valid phone number",
          });
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formDataCheck = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        registeredBusiness: registeredBusiness,
        organizationName: organizationName,
        organizationPhoneNumber: organizationPhoneNumber,
        organizationBusinessTime: organizationBusinessTime,
        organizationAddress: organizationAddress,
        dietitianDetail: dietitianDetail,
        educationQualifications: educationQualifications,
        yearsWorkingAsDietitian: yearsWorkingAsDietitianInt,
        everWorkedAsClinicalDietitian: everWorkedAsClinicalDietitian,
        hospitalName: hospitalName,
        state: state,
        city: city,
        yearsWorkingAtHospital: yearsWorkingAtHospital,
        specialization: specialization,
        nutritionConsultingForDiseases: JSON.stringify(formData),
        nutritionConsultingForDiseasesForClients: nutritionConsultingForDiseasesForClients,
        historyOfWorkingWithChild: historyOfWorkingWithChild,
        detailsOfMethodologyOrDietaryGuidelinesFollowed: detailsOfMethodologyOrDietaryGuidelinesFollowed,
        genderPreference: genderPreference,
        childrenFromBirthTillThreeYearsAge: childrenFromBirthTillThreeYearsAge,
        childrenFromTwoToTwelveYearsAge: childrenFromTwoToTwelveYearsAge,
        childrenFromThirteenToTwentyYearsAge: childrenFromThirteenToTwentyYearsAge,
        adultMaleFromTwentyOneToSixtyYearsAge: adultMaleFromTwentyOneToSixtyYearsAge,
        adultFemaleFromTwentyOneToSixtyYearsAge: adultFemaleFromTwentyOneToSixtyYearsAge,
        seniorsOfAgeGreaterThenSixty: seniorsOfAgeGreaterThenSixty,
        outstandingTestimonials: testimonials,
        sharingReferences: sharingReferences,
        referenceDetail: JSON.stringify(referenceDetail),
        preferredDayAndTime: JSON.stringify(preferredDayAndTime),
        files: files,
        testimonialFiles: testimonial,
      };
       try {
         const response = await AxiosInstance.post("dietitian/add-dietitian", formDataCheck, {
           headers: {
             "Content-Type": "multipart/form-data",
           },
         });
          Swal.fire({
            icon: "success",
            text: "Form data submitted successfully",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000); 
       } catch (error) {
         toast.error("Failed to submit information", error);
       }
    }
  };

  const getDietitianData = () => {
    AxiosInstance.get("/dietitian/getDietitian")
      .then((response) => {
        setFirstName(response?.firstName);
        setLastName(response?.lastName);
        setEmail(response?.email);
        setPhoneNumber(response?.phoneNumber)
        setRegisteredBusiness(response?.registeredBusiness);
        setOrganizationAddress(response?.organizationAddress)
        setOrganizationBusinessTime(response?.organizationBusinessTime);
        setOrganizationName(response?.organizationName);
        setOrganizationPhoneNumber(response?.organizationPhoneNumber);
        setDietitianDetail(response?.dietitianDetail);
        setEducationQualifications(response?.educationQualifications);
        setYearsWorkingAsDietitian(response?.yearsWorkingAsDietitian);
        seteverWorkedAsClinicalDietitian(response?.everWorkedAsClinicalDietitian);
        setHospitalName(response?.hospitalName);
        setState(response?.state)
        setCity(response?.city)
        setYearsWorkingAtHospital(response?.yearsWorkingAtHospital);
        setSpecialization(response?.specialization)
        setNutritionConsultingForDiseasesForClients(response?.nutritionConsultingForDiseasesForClients);
        setHistoryOfWorkingWithChild(response?.historyOfWorkingWithChild);
        setDetailsOfMethodologyOrDietaryGuidelinesFollowed(response?.detailsOfMethodologyOrDietaryGuidelinesFollowed)
        setGenderPreference(response?.genderPreference);
        setChildrenFromBirthTillThreeYearsAge(response?.childrenFromBirthTillThreeYearsAge);
        setChildrenFromTwoToTwelveYearsAge(response?.childrenFromTwoToTwelveYearsAge);
        setChildrenFromThirteenToTwentyYearsAge(response?.childrenFromThirteenToTwentyYearsAge);
        setAdultMaleFromTwentyOneToSixtyYearsAge(response?.adultMaleFromTwentyOneToSixtyYearsAge);
        setAdultFemaleFromTwentyOneToSixtyYearsAgee(response?.adultFemaleFromTwentyOneToSixtyYearsAge);
        setSeniorsOfAgeGreaterThenSixty(response?.seniorsOfAgeGreaterThenSixty);
        setSharingReferences(response?.sharingReferences);
        setPreferredDayAndTime(JSON.parse(response?.preferredDayAndTime))
        setReferenceDetail(JSON.parse(response?.referenceDetail))
        setFormData(JSON.parse(response?.nutritionConsultingForDiseases));
        setTestimonials(response?.outstandingTestimonials)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  useEffect(() => {
    getDietitianData();
  }, [])
  const AttachTestimonial = async (event) => {
    event.preventDefault();
    const newFiles = event.target.files; // Get the uploaded files
    setTestimonial((prevFiles) => [...prevFiles, ...newFiles]); // Add the new files to the existing files array

    const formData = new FormData();
    for (let i = 0; i < newFiles.length; i++) {
      formData.append("testimonialFiles[]", newFiles[i]); // Append each file to the form data
    }

    AxiosInstance.post("/dietitian/add-dietitian", formData)
      .then((response) => {
        console.log("response: ", response);
        toast.success("Document Uploaded Successfully");
      })
      .catch((error) => {
        //setError("An error occurred");
      });
  };

  const handleAttachCopies = async (event) => {
     event.preventDefault();
     const newFiles = event.target.files; // Get the uploaded files
     console.log('newFiles: ', newFiles);
     setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add the new files to the existing files array

     const formData = new FormData();
     for (let i = 0; i < newFiles.length; i++) {
       formData.append("files[]", newFiles[i]); // Append each file to the form data
     }
    console.log("formDataaa", formData)
     AxiosInstance.post("/dietitian/add-dietitian", formData)
       .then((response) => {
         console.log("response: ", response);
         toast.success("Document Uploaded Successfully");
       })
       .catch((error) => {
         //setError("An error occurred");
       });
  }

const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
  const IOSCertificate = "ISOCertificate";

  const nablCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "NABLCertificate")
    .map((certificate) => certificate.documentLink);

  const isoCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "ISOCertificate")
    .map((certificate) => certificate.documentLink);

    const addTestimonial = () => {
      if (testimonials.length < 3) {
        setTestimonials([...testimonials, ""]); 
      }
    };

    const handleInputChange = (index, value) => {
      const newTestimonials = [...testimonials];
      newTestimonials[index] = value;
      setTestimonials(newTestimonials);
    };

    useEffect(() => {
      if (testimonials.length >= 3) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }, [testimonials]);
  
  const isChecked = (category, value) => {
    return (formData && category && value && formData[category]?.ageGroup?.includes(value)) || false;
  };

  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">First Name of Clinical Dietician/Nutritionist</Label>
              <Input disabled id="" name="name" value={firstName} type="text" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">Last Name of Clinical Dietician/Nutritionist</Label>
              <Input id="" disabled name="name" value={lastName} type="text" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="examplePassword">Email</Label>
              <Input disabled id="" name="email" value={email} type="email" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">Phone Number</Label>
              <Input disabled id="phoneNumber" name="registrationNumber" value={phoneNumber} type="number" />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span style={{ color: fieldErrors.registeredBusiness ? "red" : "inherit" }}>Do you have a registered practice/organization for your business ? </span> &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                checked={registeredBusiness === true}
                onChange={(e) => {
                  setRegisteredBusiness(true);
                  setShowRegisteredOrganization(true);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                checked={registeredBusiness === false}
                onChange={(e) => {
                  setRegisteredBusiness(false);
                  setShowRegisteredOrganization(false);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(showRegisteredOrganization || registeredBusiness) && (
          <>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                id={`organizationName`}
                name="organizationName"
                placeholder="Enter Name"
                type="text"
                    value={organizationName}
                    onChange={(e) => {
                      handleOrganizationName(e);
                    }}
                    style={{ borderColor: fieldErrors.organizationName ? "red" : "inherits" }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`phoneNumber`}>Phone Number</Label>
              <Input
                id={`phoneNumber`}
                name="phoneNumber"
                placeholder="Enter Phone Number"
                type="number"
                    onChange={(e) => {
                      handleOrganizationPhoneNumber(e);
                    }}
                value={organizationPhoneNumber}
                    style={{
                      borderColor: fieldErrors.organizationPhoneNumber ? "red" : "lightgrey",
                    }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`organizationBusinessTime`}>Business Time</Label>
              <Input
                id={`organizationBusinessTime`}
                name="organizationBusinessTime"
                placeholder="Enter Email Id"
                type="time"
                    onChange={(e) => {
                      handleOrganizationBusinessTime(e);
                    }}
                value={organizationBusinessTime}
                    style={{ borderColor: fieldErrors.organizationBusinessTime ? "red" : "inherit" }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for={`organizationAddress`}>Address</Label>
                  <Input
                    id={`organizationAddress`}
                    name="phoneNumber"
                    placeholder="Enter Address"
                    type="textarea"
                    onChange={(e) => {
                      handleOrganizationAddress(e);
                    }}
                    value={organizationAddress}
                    style={{ borderColor: fieldErrors.organizationAddress ? "red" : "inherit" }}
                  />
            </FormGroup>
          </Col>
        </Row>
          </>
        )}

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label for="exampleEmail">Please narrate briefly about yourself.</Label>
              <Input
                id=""
                name="name"
                value={dietitianDetail}
                onChange={(e) => {
                  handleDietitianDetail(e);
                }}
                placeholder="Narrate about yourself"
                type="textarea"
                style={{
                  borderColor: fieldErrors.dietitianDetail ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="exampleEmail">List all your educational qualifications as a Clinical Dietician.</Label>
              <Input
                id=""
                name="name"
                value={educationQualifications}
                onChange={(e) => {
                  handleEducationQualification(e);
                }}
                placeholder="List your educational qualification"
                type="textarea"
                style={{
                  borderColor: fieldErrors.educationQualifications ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={4}>How long (years) you have now worked in this capacity?</Col>
          <Col md={4}>
            <FormGroup>
              <Input
                id=""
                name="name"
                value={yearsWorkingAsDietitian}
                onChange={(e) => {
                  handleYearsWorkingAsDietitian(e);
                }}
                placeholder="Enter years"
                type="number"
                style={{
                  borderColor: fieldErrors.yearsWorkingAsDietitian ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span style={{ color: fieldErrors.everWorkedAsClinicalDietitian ? "red" : "inherit" }}>
                Have you ever worked as a clinical dietician in a hospital or clinic setting dealing directly with patients and taking care of their dietary needs after illness?
              </span>{" "}
              &nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalDietitian"
                type="radio"
                checked={everWorkedAsClinicalDietitian === true}
                onChange={() => {
                  seteverWorkedAsClinicalDietitian(true);
                  setShowEverWorkedasClicnicalAdvisor(true);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalDietitian"
                type="radio"
                checked={everWorkedAsClinicalDietitian === false}
                onChange={() => {
                  seteverWorkedAsClinicalDietitian(false);
                  setShowEverWorkedasClicnicalAdvisor(false);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(showEverWorkedasClicnicalAdvisor || everWorkedAsClinicalDietitian) && (
          <>
            <Row></Row>
            <Row>
              <Col md={4}>
                <Label>Name of Hospital / Clinic</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={hospitalName}
                    onChange={(e) => {
                      handleHospitalName(e);
                    }}
                    placeholder="Enter name of hospital / clinic"
                    type="text"
                    className="mt-2"
                    style={{ borderColor: fieldErrors.hospitalName ? "red" : "inherit" }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>State</Label>
                <FormGroup>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    value={state}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    className="mt-2"
                    style={{ borderColor: fieldErrors.state ? "red" : "inherit" }}
                  >
                    <option value="">Select State</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>City</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={city}
                    onChange={(e) => {
                      handleCity(e);
                    }}
                    placeholder="Enter City"
                    type="text"
                    className="mt-2"
                    style={{ borderColor: fieldErrors.city ? "red" : "inherit" }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>How many years? </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="yearsWorkingAtHospital"
                    value={yearsWorkingAtHospital}
                    onChange={(e) => {
                      handleHowManyYears(e);
                    }}
                    className="mt-2"
                    style={{ borderColor: fieldErrors.yearsWorkingAtHospital ? "red" : "inherit" }}
                  >
                    <option value="">Select years</option>
                    <option value="less than 1 year">Less than 1 year</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="3-4 years">3-4 years</option>
                    <option value="5 years or more">5 years or more</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>Which Specialization : </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleSpecialization(e);
                    }}
                    value={specialization}
                    className="mt-2"
                    style={{ borderColor: fieldErrors.educationalQualification ? "red" : "inherit" }}
                  >
                    <option value="">Select Specialization</option>
                    <option value="CVD">CVD</option>
                    <option value="hypertension">Hypertension</option>
                    <option value="obesity/weightLoss">Obesity / Weight Loss</option>
                    <option value="Diabetes">Diabetes</option>
                    <option value="Colorectal cancer">Colorectal cancer</option>
                    <option value="Prostate cancer">Prostate cancer</option>
                    <option value="Cervical cancer">Cervical cancer</option>
                    <option value="Oral Cancer ">Oral Cancer </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label>For which of the following diseases, you have provided or currently providing Nutrition consulting?</Label>
            </FormGroup>
          </Col>
        </Row>

        <TableContainer sx={{ border: "1px solid black", borderRadius: "5px" }} elevation={5} className="mt-3 mb-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Disease
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  Gender
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  Age Group
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  How many years
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  How many patients/people you have helped so far? (Approx No.)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>CVD</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    value={formData?.CVD?.gender}
                    onChange={(e) => {
                      handleGenderChange("CVD", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors.educationalQualification ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("CVD", "0-3")} />}
                          label=""
                          // checked={formData?.CVD.ageGroup}
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("CVD", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("CVD", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("CVD", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.CVD?.years}
                    onChange={(e) => {
                      handleYearsChange("CVD", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.CVD?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("CVD", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Hypertension</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    value={formData?.Hypertension?.gender}
                    onChange={(e) => {
                      handleGenderChange("Hypertension", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors.educationalQualification ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Hypertension", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Hypertension", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Hypertension", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Hypertension", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Hypertension", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Hypertension", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Hypertension", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Hypertension", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Hypertension?.years}
                    onChange={(e) => {
                      handleYearsChange("Hypertension", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Hypertension?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Hypertension", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Obesity/Weight Loss</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Obesity/Weight Loss", e.target.value);
                    }}
                    value={formData?.["Obesity/Weight Loss"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Obesity/Weight Loss", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Obesity/Weight Loss", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Obesity/Weight Loss", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Obesity/Weight Loss", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Obesity/Weight Loss", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Obesity/Weight Loss", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Obesity/Weight Loss", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Obesity/Weight Loss", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Obesity/Weight Loss"]?.years}
                    onChange={(e) => {
                      handleYearsChange("Obesity/Weight Loss", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="text"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Obesity/Weight Loss"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Obesity/Weight Loss", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="text"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Diabetes</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Diabetes", e.target.value);
                    }}
                    value={formData?.Diabetes?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Diabetes", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Diabetes", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Diabetes", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Diabetes", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Diabetes?.years}
                    onChange={(e) => {
                      handleYearsChange("Diabetes", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Diabetes?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Diabetes", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Colorectal cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Colorectal cancer", e.target.value);
                    }}
                    value={formData?.["Colorectal cancer"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Colorectal cancer", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Colorectal cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Colorectal cancer", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Colorectal cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Colorectal cancer", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Colorectal cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Colorectal cancer", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Colorectal cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Colorectal cancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("Colorectal cancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Colorectal cancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Colorectal cancer", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Prostate cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Prostate cancer", e.target.value);
                    }}
                    value={formData?.["Prostate cancer"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Prostate cancer", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Prostate cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Prostate cancer", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Prostate cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Prostate cancer", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Prostate cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Prostate cancer", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Prostate cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Prostate cancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("Prostate cancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Prostate cancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Prostate cancer", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Cervical cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Cervical cancer", e.target.value);
                    }}
                    value={formData?.["Cervical cancer"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Cervical cancer", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Cervical cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Cervical cancer", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Cervical cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Cervical cancer", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Cervical cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Cervical cancer", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Cervical cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Cervical cancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("Cervical cancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Cervical cancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Cervical cancer", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Oral cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Oral cancer", e.target.value);
                    }}
                    value={formData?.["Oral cancer"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="0-3" checked={isChecked("Oral cancer", "0-3")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Oral cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="3-13" checked={isChecked("Oral cancer", "3-13")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Oral cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value="13-21" checked={isChecked("Oral cancer", "13-21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Oral cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value=">21" checked={isChecked("Oral cancer", ">21")} />}
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Oral cancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Oral cancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("Oral cancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Oral cancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Oral cancer", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>
                For which of the following diseases you would be interested in providing diet consulting to our clients? (Please note that most of our clients would need Preventive Diet solutions
                rather than cure solutions as our aim is to prevent or delay the onset of these diseases through lifestyle changes).
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("CVD")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="CVD"
              value="CVD"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Hypertension")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Hypertension"
              value="Hypertension"
            />
          </Col>
          <Col md={3}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Obesity/Weight Loss")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Obesity/Weight Loss"
              value="Obesity/Weight Loss"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Diabetes")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Diabetes"
              value="Diabetes"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Colorectal cancer")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Colorectal cancer"
              value="Colorectal cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Prostate cancer")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Prostate cancer"
              value="Prostate cancer"
            />
          </Col>
          <Col md={3}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Cervical cancer")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Cervical cancer"
              value="Cervical cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes("Oral Cancer")}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Oral Cancer"
              value="Oral Cancer"
            />
          </Col>
        </Row>
        <Row>
          {console.log("other", diseasesOtherValue)}
          <Col md={4}>
            <Label>Others : </Label>
            <Input
              id=""
              name="name"
              value={nutritionConsultingForDiseasesForClients}
              onChange={(e) => {
                handleDiseasesOtherValue(e);
              }}
              onBlur={handleOtherDiseases}
              placeholder="Enter other diseases"
              type="text"
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>Are you comfortable working with children of all ages, guiding them towards a healthier and better lifestyle. Please elaborate your work with children in the past (if any).</span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={historyOfWorkingWithChild}
              onChange={(e) => {
                handleHistoryOfWorkingWithChild(e);
              }}
              placeholder="Elaborate your work with children in the past"
              type="textarea"
              style={{
                borderColor: fieldErrors.historyOfWorkingWithChild ? "red" : "lightgrey",
              }}
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>Do you follow any specific methodology or national/international dietary guidelines while providing diet consultation? Please explain briefly your approach.</span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={detailsOfMethodologyOrDietaryGuidelinesFollowed}
              onChange={(e) => {
                handleDetailsOfMethodologyOrDietaryGuidelinesFollowed(e);
              }}
              placeholder="Specific methodology or national/international dietary guidelines"
              type="textarea"
              style={{
                borderColor: fieldErrors.detailsOfMethodologyOrDietaryGuidelinesFollowed ? "red" : "lightgrey",
              }}
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span style={{ color: fieldErrors.genderPreference ? "red" : "inherit" }}>
              Do you find yourself more comfortable working with specific gender (male vs female) or you are equally comfortable working with both gender?
            </span>
          </Col>
        </Row>
        <Row>
          {console.log("genderpre", genderPreference)}
          <Col md={6}>
            <FormGroup>
              <Input
                name="genderPreference"
                value="male"
                checked={genderPreference === "male"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Male
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="female"
                checked={genderPreference === "female"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Female
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="both"
                checked={genderPreference === "both"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Both
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Grid container spacing={2} mb={2}>
          <Grid item>
            <Label>
              Based on your past experiences, rate your own performance in terms of positive outcome for your clients, on a scale of 1 to 5 (5= Outstanding, 4= Excellent, 3= Good 2= Fair 1= Not sure,
              0= No experience)
            </Label>
          </Grid>
        </Grid>

        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div style={{ display: "flex", gap: "85px" }}>
              <div>0</div>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
            </div>
          </Col>
        </Row>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                // style={{
                //   color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                // }}
              >
                1. Children (from Birth to 3 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="0-3"
                  value="No experience"
                  checked={childrenFromBirthTillThreeYearsAge === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Not sure"
                  checked={childrenFromBirthTillThreeYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Fair"
                  checked={childrenFromBirthTillThreeYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Good"
                  checked={childrenFromBirthTillThreeYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Excellent"
                  checked={childrenFromBirthTillThreeYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Outstanding"
                  checked={childrenFromBirthTillThreeYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                // style={{
                //   color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                // }}
              >
                2. Children (2-12 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="2-12"
                  value="No experience"
                  checked={childrenFromTwoToTwelveYearsAge === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Not sure"
                  checked={childrenFromTwoToTwelveYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Fair"
                  checked={childrenFromTwoToTwelveYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Good"
                  checked={childrenFromTwoToTwelveYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Excellent"
                  checked={childrenFromTwoToTwelveYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge(e);
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Outstanding"
                  checked={childrenFromTwoToTwelveYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                3. Children (13-21 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="13-21"
                  value="No experience"
                  checked={childrenFromThirteenToTwentyYearsAge === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Not sure"
                  checked={childrenFromThirteenToTwentyYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Fair"
                  checked={childrenFromThirteenToTwentyYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Good"
                  checked={childrenFromThirteenToTwentyYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Excellent"
                  checked={childrenFromThirteenToTwentyYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Outstanding"
                  checked={childrenFromThirteenToTwentyYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                4. Adults-Male (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60"
                  value="No experience"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Not sure"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Fair"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Good"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Excellent"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Outstanding"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                5. Adult-Female (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60F"
                  value="No experience"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Not sure"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Fair"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Good"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Excellent"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Outstanding"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                6. Seniors (Age &gt;60 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="<60"
                  value="No experience"
                  checked={seniorsOfAgeGreaterThenSixty === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Not sure"
                  checked={seniorsOfAgeGreaterThenSixty === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Fair"
                  checked={seniorsOfAgeGreaterThenSixty === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Good"
                  checked={seniorsOfAgeGreaterThenSixty === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Excellent"
                  checked={seniorsOfAgeGreaterThenSixty === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Outstanding"
                  checked={seniorsOfAgeGreaterThenSixty === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>Please cut and paste below three (3) of the most outstanding comments/testimonials you have received from your clients in the past for the services you have provided to them. </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span>
              <label
                htmlFor="isoCertificate"
                style={{
                  cursor: "pointer",
                  color: "rgb(83, 83, 233)",
                  textDecoration: "underline",
                  marginLeft: "20px",
                }}
              >
                Attach client testimonials
              </label>
            </span>{" "}
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="isoCertificate"
                className="file-upload-input"
                name="isoCertificate"
                // onChange={(event) => handleFileSubmit(event, row.id)}
                style={{ display: "none" }}
                onChange={(event) => AttachTestimonial(event)}
                multiple
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            {isoCertificates.length > 0 ? isoCertificates.map((link, index) => <p>{link}</p>) : ""}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>If you would rather mention the testimonials, then do so in the space provided below. Just cut and paste up to three (3) testimonials. Click on “Add testimonial” below.</Label>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button type="button" className="btn btn-secondary" onClick={addTestimonial} disabled={buttonDisabled}>
              Add Testimonial
            </Button>
          </Col>
          <Row>
            {}
            {testimonials.map((testimonial, index) => (
              <Col md={12} key={index}>
                <Input
                  id={`testimonial-${index}`}
                  name={`testimonial-${index}`}
                  value={testimonial}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Enter Testimonial"
                  type="textarea"
                  className="mt-2"
                  style={{
                    borderColor: "lightgrey",
                  }}
                />
              </Col>
            ))}
          </Row>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <h4>Reference</h4>
        <hr style={{ width: "10%", marginTop: "3px", height: "2px" }}></hr>
        <Row>
          <Col>
            <span style={{ color: fieldErrors.sharingReferences ? "red" : "inherit" }}>
              Would you be open to sharing 3 references of your past or current clients (1= children, 1= Female Adult and 1 = Male Adult) who can give us honest feedback of their experiences in
              working with you for your Nutrition consulting services?
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                checked={sharingReferences === true}
                onChange={() => {
                  setShowReferences(true);
                  setSharingReferences(true);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                checked={sharingReferences === false}
                onChange={() => {
                  setShowReferences(false);
                  setSharingReferences(false);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        {(showReferences || sharingReferences) && (
          <>
            <Label>
              Please provide below their contact information and let them know that will be receiving a call from Living Scope Health Marketing team member. It will be a quick conversation only and we
              won’t take much of their time.{" "}
            </Label>
            <h5>Reference # 1</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference1", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate("reference1", "mobile", e.target.value);
                  }}
                  placeholder="Enter Mobile"
                  type="text"
                  className="mt-2"
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 2</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference2", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate("reference2", "mobile", e.target.value);
                  }}
                  placeholder="Enter Mobile"
                  type="text"
                  className="mt-2"
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 3</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference3", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate("reference3", "mobile", e.target.value);
                  }}
                  placeholder="Enter Mobile"
                  type="text"
                  className="mt-2"
                />
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>

        <Row>
          <Col>
            <span style={{ color: fieldErrors.preferredDayAndTime ? "red" : "inherit" }}>
              Please check below your preferred time to provide diet consulting services to our clients. You will be later given a weekly calendar to manage your own preferred schedule on our Uncurl:
              health portal. Our clients will book your consultation based on your availability only.{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="weekdays">Select Weekdays:</Label>
              <Input
                type="select"
                name="weekdays"
                id="weekdays"
                value={preferredDayAndTime?.Weekdays}
                onChange={(e) => {
                  handleWeekDays("weekdays", e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="time">Select Time:</Label>
              <Input
                type="time"
                name="time"
                id="time"
                value={preferredDayAndTime?.time}
                onChange={(e) => {
                  handleTime("time", e.target.value);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <Label>Please attach below copies of your credentials like Diplomas, course certificates, awards, newspaper cuttings etc. that adds to your credentials as a leading Nutritionist.</Label>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span>
              <label
                htmlFor="isoCertificate"
                style={{
                  cursor: "pointer",
                  color: "rgb(83, 83, 233)",
                  textDecoration: "underline",
                  marginLeft: "20px",
                  color: fieldErrors.ISOreports ? "red" : "blue",
                }}
              >
                Attach copies
              </label>
            </span>{" "}
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="isoCertificate"
                className="file-upload-input"
                name="isoCertificate"
                // onChange={(event) => handleFileSubmit(event, row.id)}
                style={{ display: "none" }}
                onChange={(event) => handleAttachCopies(event)}
                multiple
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            {isoCertificates.length > 0 ? isoCertificates.map((link, index) => <p>{link}</p>) : ""}
            <br></br>
            <div>
              {/* <b>Reports : </b>
              {labPartnerSampleTest?.map((sampleTestObj, index) => (
                <span key={index}>
                  {sampleTestObj.sampleTestLink}
                  {index !== labPartnerSampleTest?.length - 1 && ", "}
                </span>
              ))} */}
            </div>
          </Col>
        </Row>
      </Form>
      <center>
        <Button
          onClick={handleSubmit}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(83, 83, 233)",
          }}
        >
          Save
        </Button>
      </center>
    </>
  );
};

export default DieticianGeneralInfo;
