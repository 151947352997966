import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

const PatientTestResultModal = ({ testResultData }) => {
  const parseDesirableValue = (desirableValue) => {
    let minValue = null;
    let maxValue = null;

    if (desirableValue.includes(">")) {
      minValue = parseFloat(desirableValue.replace(">", "").trim());
    } else if (desirableValue.includes("<")) {
      maxValue = parseFloat(desirableValue.replace("<", "").trim());
    } else if (desirableValue.includes("-")) {
      [minValue, maxValue] = desirableValue
        .split("-")
        .map((val) => parseFloat(val.trim()));
    } else if (desirableValue.toLowerCase().includes("to")) {
      [minValue, maxValue] = desirableValue
        .toLowerCase()
        .split("to")
        .map((val) => parseFloat(val.trim()));
    }

    return { minValue, maxValue };
  };

  const isValueOutOfRange = (value, { minValue, maxValue }) => {
    if (minValue !== null && maxValue === null) {
      return value < minValue;
    }
    if (maxValue !== null && minValue === null) {
      return value > maxValue;
    }
    if (minValue !== null && maxValue !== null) {
      return value < minValue || value > maxValue;
    }
    return false; // default case
  };
  useEffect(() => {
    const hasOutOfRange = testResultData.preventiveTestResult.some((test) => {
      const { minValue, maxValue } = parseDesirableValue(test.desirableValue);
      const value = parseFloat(test.value);
      return isValueOutOfRange(value, { minValue, maxValue });
    });
  }, [testResultData]);
  console.log("testResultRowData", testResultData);
  return (
    <div>
      <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
        <Table>
          <TableRow>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Test Name</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Test Value</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Unit</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Interpretation</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Desirable Value</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Why These Tests Are Done ?</b>
              </p>
            </TableCell>
          </TableRow>
          <TableBody>
            {testResultData?.preventiveTestResult &&
              testResultData?.preventiveTestResult.map((test, index) => {
                const { minValue, maxValue } = parseDesirableValue(
                  test.desirableValue
                );
                const value = parseFloat(test.value);
                const isOutOfRange = isValueOutOfRange(value, {
                  minValue,
                  maxValue,
                });
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {test.testName}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        color: isOutOfRange ? "red" : "black",
                      }}
                    >
                      {test.value}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {test.unit}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {test.interpretation}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {test.desirableValue}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {test.whyTheseTestAreDone}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientTestResultModal;
