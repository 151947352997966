
import * as React from "react";
import { useEffect, useState } from "react";
import { GrRefresh } from "react-icons/gr";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { strings } from "../../constants/strings";
import Box from "@mui/material/Box";
import MUITable from "../../shared/MUITable";
import Spinner from "../../shared/Spinner";

import PageTitle from "../../shared/PageTitle";
import ModelPopUp from "../../shared/model/ModelPopUp";
import { event } from "react-ga";
//   const [recordsListData, setRecordsListData] = useState([]);
const columns = [
  {
    id: "dateRecommended",
    label: "Date Recommended",
    isDate: true,
  },
  {
    id: "medicalCondition",
    label: "Medical Condition",
  },
  {
    id: "lrid",
    label: "LRID",
  },
  {
    id: "preventiveTest",
    label: "Preventive Test",
  },
  {
    id: "testName",
    label: "Test Name",
  },
  {
    id: "attachReport",
    label: "Attach Test Report",
    isLink: true,
  },
  {
    id: "taskStatus",
    label: "Status",
  },
];

export const PreventiveTable = () => {
  const [filterDate, setFilterDate] = useState("");
  const [testResult, setTestResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [testRecordListData, setTestRecordListData] = useState();
  const [recordsListData, setRecordsListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [isDummyData, setIsDummyData] = useState(false);
  const [lrid, setLrid] = useState(""); 
  const [responseLrid, setResponseLrid] = useState("");
  const [fileName, setFileName] = useState(null);
  const [showExplaination, setShowExplaination] = useState(false);
  const [taskResponse, setTaskResponse] = useState("");

    useEffect(() => {
      getTestRecordsList();
      AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  
  let checkLrid = [];
  let updateStatus;
  const getTestRecordsList = async () => {
      AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
        .then((response) => {
        checkLrid = response.map((obj) => obj.lrid);
        })
        .catch((error) => {
          console.log(error);
        });
      AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices")
        .then((response) => {
          let check = response.map((obj) => obj.lrid)
      })
     try {
       setIsLoading(true);
       const response = await AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices");
       const responseData = response;
       let check = response.map((obj) => obj.lrid);
       updateStatus = check.filter((id) => checkLrid.includes(id)) ? "Complete" : "Incomplete";
       const testReportList = responseData?.map((obj) => {
       const isComplete = check.some((id) => checkLrid.includes(obj.lrid));
       const taskStatus = isComplete ? "Complete" : "Incomplete";
       const setLridNew = obj.lrid;
         setLrid(setLridNew);
         return {
           dateRecommended: obj.dateRecommended,
           medicalCondition: obj.medicalCondition,
           lrid: obj.lrid,
           preventiveTest: obj.preventiveTest,
           testName: obj.bookForTest,
           attachReport: (
             <div>
               <label htmlFor="fileUpload" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
                 + Attach Report
               </label>
               {isLoading == true ? (
                 <div>
                   <Spinner />
                   <br />
                   <p>Please wait your prescription is uploading</p>
                 </div>
               ) : (
                 ""
               )}
             </div>
           ),
           taskStatus: taskStatus,
         };
       });
       setRecordsListData(testReportList);
       setIsLoading(false);
     } catch (error) {
       console.error("Error fetching test records:", error);
       setIsLoading(false);
     }
   };
    const handleFileChange = async (event, selectedRowData) => {
      event.preventDefault();
      const file = event.target.files[0];
      const LRID = selectedRowData.rowData.lrid;
       setSelectedFile(file);
          let fileCheck = fileName?.split(".")?.pop();
          if (fileCheck == "gif" || fileCheck == "jfif" || fileCheck == "csv" || fileCheck == "xls") {
            toast.error("GIF, csv, xls and Jfif file are not allowed");
          } else {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("lrid", LRID);
            setLoading(true);
            AxiosInstance.post("/test-report/uploadPreventiveReport", formData)
              .then((response) => {
                toast.success("Document Uploaded Successfully");
                setUploadFlag(true);
                setIsOpen(false);
                setLoading(false);
                setSelectedFile(null);
                setFileName(null);
              })
              .catch((error) => {
                //setError("An error occurred while uploading the file.");
                setLoading(false);
              });
          }
      }
    // };
  const onInputClick = (event) => {
  
    };
  return (
    <>
      {isReviewFormOpen && <ReviewsTabs currentTab={1} onRefresh={getTestRecordsList} selectedReport={selectedReport} setIsReviewFormOpen={setIsReviewFormOpen} isDummyData={isDummyData} />}
      <Box sx={{ width: "100%" }}>
        <Row style={{ alignItems: "end" }}>
          <Col md={8}>
            <h2 className="pageTitle">Preventive Test Records</h2>
            <div className="text-content">
              <p className="text-style" style={{ fontSize: "18px" }}>
                Please attach all test Reports for Preventive test recommendations made in the app. You can see a snapshot of the report then in your MR - Digital Wallet.
              </p>
            </div>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="fileUpload"
                className="file-upload-input"
                name="file"
                onChange={(event) => handleFileChange(event, selectedRowData)}
                onClick={(event) => onInputClick(selectedRowData)}
              />
              {isLoading == true ? (
                <div>
                  <Spinner />
                  <br />
                  <p>Please wait your prescription is uploading</p>
                </div>
              ) : (
                <>Attach</>
              )}
            </div>
          </Col>
          <Col md={4} className="upload-button"></Col>
        </Row>
      </Box>
      {!!recordsListData && <MUITable data={recordsListData} columns={columns} isLoading={isLoading} filterDate={filterDate} setSelectedRowData={setSelectedRowData} />}
    </>
  );
 
}
