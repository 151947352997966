import {
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import { getColorByLabel } from "../../../util/Helper";

const LifeStyleModificationRecommendation = () => {
    const data = [
        {disease: "Heart Risk",
        intensity: "High",
        thingsYouCanNotChange: <ul style={{listStyle :"outside"}}><li>Age</li><li>Family History of Cardiovascular Disease</li> <li>Sex</li></ul>,
        thingsYouCanChange: <ul style={{listStyle :"outside"}}><li>High BP</li><li>High Blood Sugar</li><li>BMI</li><li>Diet</li></ul>,
        socialFactors: <ul style={{listStyle :"outside"}}><li>Environment</li><li>Income</li><li>Social Isolation</li></ul>,
        otherFactors : ""
    },
    {
        disease: "Diabetes",
        intensity: "Medium",
        thingsYouCanNotChange: <ul style={{listStyle :"outside"}}><li>Age</li><li>Family History of Diabetes</li> <li>Sex</li></ul>,
        thingsYouCanChange: <ul style={{listStyle :"outside"}}><li> Low levels of HDL Cholestrol </li><li>Elevated blood sugar levels</li><li>High blood pressure (systolic)</li><li>Diet</li></ul>,
        socialFactors: <ul style={{listStyle :"outside"}}><li>Low socioeconomic status</li></ul>,
        otherFactors: <ul style={{listStyle :"outside"}}><li>Presence of autoantibodies</li></ul>
    }
    ]
  return <div>
        {/* <TableContainer className="risk-table-wrapper">
            <Table size="small">
                <TableHead sx={{ backgroundColor: "#aaa" }}>
                    <TableRow>
                    <TableCell >Disease</TableCell>
                    <TableCell >Intensity</TableCell>
                    <TableCell >Things You Can't Change</TableCell>
                    <TableCell >Things You Can Change</TableCell>
                    <TableCell >Social Factors</TableCell>
                    <TableCell >Other Factors</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                    <TableRow>
                    <TableCell >
                        {row.disease}
                    </TableCell>
                    <TableCell >{row.intensity}</TableCell>
                    <TableCell >{row.thingsYouCanNotChange}</TableCell>
                    <TableCell >{row.thingsYouCanChange}</TableCell>
                    <TableCell >{row.socialFactors}</TableCell>
                    <TableCell >{row.otherFactors}</TableCell>
                    </TableRow>
                    ))} 
                </TableBody>
            </Table>
        </TableContainer> */}
        <Row className="d-flex">
            <Col lg={6} className="d-flex">
                <Card className="flex-fill" style={{padding: "10px" , border: "1px solid black"}} elevation={5}>
                    <h5>Factors Used to Calculate Heart Risk</h5>
                        <Row className="mt-2 mb-2">
                            <Col lg={4}>Risk Category</Col>
                            <Col lg={6}>Risk Factors</Col>
                            <Col lg={2} style={{textAlign: "center"}}>Intensity</Col>
                        </Row>

                        <Row className="mt-2">

                            <Col lg={4}><div><b>Things You Can't Change</b></div></Col>
                            <Col lg={6}><div><ul style={{listStyle :"outside"}}><li>Age</li><li>Family History of Cardiovascular Disease</li> <li>Sex</li></ul></div></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>

                        <Row>
                            <Col lg={4}><div><b>Things You can Change</b></div></Col>
                            <Col lg={6}><div><ul style={{listStyle :"outside"}}><li>High BP</li><li>High Blood Sugar</li><li>BMI</li><li>Diet</li></ul></div></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>

                        <Row>
                            <Col lg={4}><div><b>Social Factors</b></div></Col>
                            <Col lg={6}><div ><ul style={{listStyle :"outside"}}><li>Environment</li><li>Income</li><li>Social Isolation</li></ul></div></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>                 
                </Card>
            </Col>
            <Col lg={6} className="d-flex">
            <Card className="flex-fill" style={{padding: "10px" , border: "1px solid black"}} elevation={5}>
                    <h5>Factors Used to Calculate Diabetes</h5>
                       <Row className="mt-2 mb-2">
                            <Col lg={4}>Risk Category</Col>
                            <Col lg={6}>Risk Factors</Col>
                            <Col lg={2} style={{textAlign: "center"}}>Intensity</Col>
                        </Row>
                       <Row>
                            <Col lg={4}><div> <b>Things You Can't Change </b></div></Col>
                            <Col lg={6}><div><ul style={{listStyle :"outside"}}><li>Age</li><li>Family History of Diabetes</li> <li>Sex</li></ul></div></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>
                        <Row>
                            <Col lg={4}><div><b>Things You Can Change </b></div></Col>
                            <Col lg={6}><ul style={{listStyle :"outside"}}><li> Low levels of HDL Cholestrol </li><li>Elevated blood sugar levels</li><li>High blood pressure (systolic)</li><li>Diet</li></ul></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>

                        <Row>
                            <Col lg={4}><div><b>Social Factors</b></div></Col>
                            <Col lg={6}><ul style={{listStyle :"outside"}}><li>Low socioeconomic status</li></ul></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>

                        <Row>
                            <Col lg={4}><div><b>Other Factors </b></div></Col>
                            <Col lg={6}><ul style={{listStyle :"outside"}}><li>Presence of autoantibodies</li></ul></Col>
                            <Col lg={2} style={{textAlign: "center"}}><span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel("Medium") }}
                            ></span></Col>
                        </Row>
                    
             </Card>
              {/* <div className="meter-content-wrapper mb-5">
            <div className="meter-content-inner summery">
              <div className="meter-head">
                <h4> Risk Factors</h4>
              </div>
              <div className="meter-content">
                <p>
                The following are common risk factors for both the diseases for which you are at risk because of Lifestyle Habits.
                </p>
                <p style={{marginTop: "10px" , marginBottom: "10px"}}>
                These are modifiable risk factors and you should take concrete steps to work on these to reduce your risk for both the diseases.
                </p>
                <p>
                Please remember pevention is 100 times better than cure .
                </p>
              </div>
              <div style={{marginTop: "20px"}}>
                <h6>High Blood Pressure</h6>
                <h6>Physical Inactivity</h6>
                <h6>Diet</h6>
              </div>
            </div>
              </div> */}
            </Col>
        </Row>
         <div className="meter-content-wrapper mb-5">
            <div className="meter-content-inner summery">
              <div className="meter-head">
                <h4>Common Risk Factors</h4>
              </div>
              <div className="meter-content">
                <p>
                The following are common risk factors for both the diseases for which you are at risk because of Lifestyle Habits.
                </p>
                <div style={{marginTop: "10px"}}>
                  <ul style={{listStyle: "disc"}}>
                    <li><h6>High Blood Pressure</h6></li>
                    <li><h6>Physical Inactivity</h6></li>
                    <li><h6>Diet</h6></li>
                  </ul> 
              </div>
                <p style={{marginTop: "5px" , marginBottom: "5px"}}>
                These are modifiable risk factors and you should take concrete steps to work on these to reduce your risk for both the diseases.
                </p>
                <p>
                Please remember pevention is 100 times better than cure .
                </p>
              </div>
              
            </div>
         </div>
</div>;
};

export default LifeStyleModificationRecommendation;