import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import Diet from "./Diet";
import MyFitness from "./MyFitness";
import FitnessIntervention from "./FitnessIntervention";

const PhysicalExercise = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  return (
    <div style={{}}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Tab
            label="My Fitness"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "end",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
          <Tab
            label="Fitness Intervention"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <MyFitness></MyFitness>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <FitnessIntervention></FitnessIntervention>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default PhysicalExercise;
