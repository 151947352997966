import React, { useEffect, useState } from "react";
import { Button, Card, TextField } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Row } from "reactstrap";
import "./DoctorScreens.css";
import { BsTextareaResize } from "react-icons/bs";

const DoctorReview = ({ selectedReport }) => {
  const [doctorComment, setDoctorComment] = useState("");

  //   useEffect(() => {
  //     setValue("testReportId", selectedReport?.id || 0);
  //     setValue("patientReview", selectedReport?.patientReview || "");
  //   }, [selectedReport]);

  const onSubmit = async (data) => {
    await AxiosInstance.post(`test-report/updateReview`, data)
      .then((res) => {
        toast.success("Review Submitted successfully");
        onRefresh();
        setIsReviewFormOpen(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
    reset();
  };

  const handleDoctorCommentChange = (e) => {
    setDoctorComment(e.target.value);
  };

  return (
    <Row>
      <Col lg={12}>
        <Card
          sx={{
            borderRadius: "5px",
            padding: "10px",
            border: "1px solid black",
          }}
          elevation={5}
        >
          <div className="DoctorChats">
            <span style={{ color: "green" }}>Doctor: </span> Took Medicines at
            Night xcvbnmywwdtyfghhhhh
          </div>
          <div className="PatientChatsContainer">
            <div className="PatientChats">
              <span style={{ color: "orangered" }}>Patient:</span> Thanks
            </div>
          </div>

          <div className="DoctorChats">
            <span style={{ color: "green" }}>Doctor: </span>
            <TextField
              onChange={handleDoctorCommentChange}
              value={doctorComment}
              fullWidth
              sx={{ backgroundColor: "white" }}
              placeholder="Type here..."
            />
          </div>

          {doctorComment && (
            <Button
              type="submit"
              variant="contained"
              //   disabled={isDummyData}
            >
              Submit
            </Button>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default DoctorReview;
