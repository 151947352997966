import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./formStyle.css";
import {
  Checkbox,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputField from "./InputField";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { nameValidation } from "../../util/validations";
import { strings } from "../../constants/strings";
import { yupResolver } from "@hookform/resolvers/yup";
import iconDate from "../../../static/img/icon-filled-events-3.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import Swal from "sweetalert2";

const ChildrenEnvironment13to21Questions = ({ onBack }) => {
  const [environmentStep, setEnvironmentStep] = useState(0);
  const [applies, setApplies] = useState("");
  const [otherApplies, setOtherApplies] = useState("");
  const [highestLevelOfEducation, sethighestLevelOfEducation] = useState("");
  const [employmentStatus, setemploymentStatus] = useState("");
  const [monthlySalary, setmonthlySalary] = useState(null);
  const [annualIncome, setannualIncome] = useState("");
  const [financiallyStable, setfinanciallyStable] = useState(null);
  const [freeFromPsychologicalStress, setfreeFromPsychologicalStress] =
    useState(null);
  const [cleanAir, setcleanAir] = useState(null);
  const [pollutedAir, setpollutedAir] = useState(null);
  const [occupation, setoccupation] = useState("");
  const [timeSpentInTraffic, settimeSpentInTraffic] = useState("");
  const [workMode, setworkMode] = useState("");
  const [otherworkMode, setOtherworkMode] = useState("");
  const [timeSpentOnLaptop, settimeSpentOnLaptop] = useState("");
  const [relationshipStatus, setrelationshipStatus] = useState("");
  const [militaryService, setmilitaryService] = useState(null);
  const [familyMembers, setFamilyMembers] = useState("");
  const [otherFamilyValue, setOtherFamilyValue] = useState("");
  const [otherTakeCareWillIll, setOtherTakeCareWillIll] = useState("");
  const [takeCaresWhenIll, settakeCaresWhenIll] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [totalChildrenInHousehold, settotalChildrenInHousehold] =
    useState(null);
  const [agesOfChildrenInHousehold, setagesOfChildrenInHousehold] =
    useState("");
  const [livingArrangement, setlivingArrangement] = useState("");
  const [problemsWith, setproblemsWith] = useState("");
  const [depressed, setdepressed] = useState("");
  const [timeSpentOnMobile, settimeSpentOnMobile] = useState("");
  const [timeSpentOnTv, settimeSpentOnTv] = useState("");
  const [intensityOfDepression, setintensityOfDepression] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldErrors2, setFieldErrors2] = useState({});
  const [fieldErrors3, setFieldErrors3] = useState({});
  const [familyRelation, setFamilyRelation] = useState();
  const [familyInfo, setFamilyInfo] = useState();

  const handleannualIncomeChange = (event) => {
    setannualIncome(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      annualIncome: false,
    }));
  };

  const handlepollutedAirChange = (event) => {
    const value = event.target.value === "true";
    setpollutedAir(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      pollutedAir: false,
    }));
  };

  const handlecleanAirChange = (event) => {
    const value = event.target.value === "true";
    setcleanAir(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      cleanAir: false,
    }));
  };

  const handlefreeFromPsychologicalStressChange = (event) => {
    const value = event.target.value === "true";
    setfreeFromPsychologicalStress(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      freeFromPsychologicalStress: false,
    }));
  };

  const handlefinanciallyStableChange = (event) => {
    const value = event.target.value === "true";
    setfinanciallyStable(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      financiallyStable: false,
    }));
  };

  const handlemonthlySalaryChange = (event) => {
    setmonthlySalary(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      monthlySalary: false,
    }));
  };

  const handlehighestLevelOfEducationChange = (event) => {
    sethighestLevelOfEducation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      highestLevelOfEducation: false,
    }));
  };

  const handleemploymentStatusChange = (event) => {
    setemploymentStatus(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      employmentStatus: false,
    }));
  };

  const handleoccupationChange = (event) => {
    setoccupation(event.target.value);
  };

  const handletimeSpentInTrafficChange = (event) => {
    settimeSpentInTraffic(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      timeSpentInTraffic: false,
    }));
  };

  const handletimeSpentOnLaptopChange = (event) => {
    settimeSpentOnLaptop(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      timeSpentOnLaptop: false,
    }));
  };

  const handlerelationshipStatusChange = (event) => {
    setrelationshipStatus(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      relationshipStatus: false,
    }));
  };

  const handlemilitaryServiceChange = (event) => {
    const value = event.target.value === "true";
    setmilitaryService(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      militaryService: false,
    }));
  };

  const handletakeCaresWhenIllChange = (event) => {
    settakeCaresWhenIll(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      takeCaresWhenIll: false,
    }));
  };

  const handlelivingArrangementChange = (event) => {
    setlivingArrangement(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      livingArrangement: false,
    }));
  };

  const handleagesOfChildrenInHouseholdChange = (event) => {
    const value = event.target.value;

    // Toggle the selected family member
    setagesOfChildrenInHousehold((prevMembers) => {
      if (prevMembers.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });
  };

  const handletotalChildrenInHouseholdChange = (event) => {
    settotalChildrenInHousehold(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      totalChildrenInHousehold: false,
    }));
  };

  const handleproblemsWithChange = (event) => {
    setproblemsWith(event.target.value);
  };

  const handletimeSpentOnMobileChange = (event) => {
    settimeSpentOnMobile(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      timeSpentOnMobile: false,
    }));
  };

  const handletimeSpentOnTvChange = (event) => {
    settimeSpentOnTv(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      timeSpentOnTv: false,
    }));
  };

  const handleintensityOfDepressionChange = (event) => {
    setintensityOfDepression(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      intensityOfDepression: false,
    }));
  };

  const handledepressedChange = (event) => {
    setdepressed(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      depressed: false,
    }));
  };

  const handleFamilyMemberChange = (event) => {
    const value = event.target.value;
    // Toggle the selected family member
    setFamilyMembers((prevMembers) => {
      if (prevMembers.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });

    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      familyMembers: false,
    }));

    // If "Other" is selected, clear otherValue
    if (value !== "Other") {
      setOtherFamilyValue("");
    }
  };

    const handleTakeCareWillIll = (event) => {
      const value = event.target.value;
      // Toggle the selected family member
      settakeCaresWhenIll((prevMembers) => {
        if (prevMembers.includes(value)) {
          return prevMembers.filter((member) => member !== value);
        } else {
          return [...prevMembers, value];
        }
      });

      setFieldErrors2((prevErrors) => ({
        ...prevErrors,
        familyMembers: false,
      }));

      // If "Other" is selected, clear otherValue
      if (value !== "Other") {
        setOtherFamilyValue("");
      }
    };

  const handleOtherFamilyValueChange = (event) => {
    setOtherFamilyValue(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      familyMembers: false,
    }));
  };

  const handleAccommodationChange = (event) => {
    const value = event.target.value;
    setAccommodation(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      accommodation: false,
    }));

    // If "Other" is selected, clear otherValue
    if (value !== "Other") {
      setOtherValue("");
    }
  };

  const handleOtherValueChange = (event) => {
    setOtherValue(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      accommodation: false,
    }));
  };

  const handleInputBlurChildren = () => {
    const trimmedValue = otherValue.trim();
    console.log("trimmedValue: ", trimmedValue);
    if (trimmedValue !== "" && !accommodation.includes(trimmedValue)) {
      setOtherValue(trimmedValue);
      setAccommodation(trimmedValue);
    }
  };

  const handleAppliesChange = (event) => {
    const value = event.target.value;
    setApplies(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      applies: false,
    }));

    // If "Other" is selected, clear otherValue
    if (value !== "Other") {
      setOtherApplies("");
    }
  };

  const handleOtherAppliesValueChange = (event) => {
    setOtherApplies(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      applies: false,
    }));
  };

  const handleInputBlur = () => {
    const trimmedValue = otherApplies.trim();
    console.log("trimmedValue: ", trimmedValue);
    if (trimmedValue !== "" && !applies.includes(trimmedValue)) {
      setOtherApplies(trimmedValue);
      setApplies(trimmedValue);
    }
  };

  const handleWorkModeChange = (event) => {
    const value = event.target.value;
    setworkMode(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      workMode: false,
    }));

    // If "Other" is selected, clear otherValue
    if (value !== "Other") {
      setOtherworkMode("");
    }
  };

  const handleInputBlur2 = () => {
    const trimmedValue = otherworkMode.trim();
    if (trimmedValue !== "" && !workMode.includes(trimmedValue)) {
      console.log("workMode", workMode);
      setworkMode(trimmedValue);
      setOtherworkMode(trimmedValue);
    }
  };

  const handleInputBlurFamily = () => {
    const trimmedValue = otherFamilyValue.trim();
    if (trimmedValue !== "" && !familyMembers.includes(trimmedValue)) {
      setOtherFamilyValue(trimmedValue);
      setFamilyMembers([...familyMembers, "OtherValue:" + trimmedValue]);
    }
  };

  const handleInputTakeCareWillIll = () => {
    const trimmedValue = otherTakeCareWillIll.trim();
    if (trimmedValue !== "" && !takeCaresWhenIll.includes(trimmedValue)) {
       setOtherTakeCareValue(trimmedValue);
       setOtherTakeCareWillIll([...takeCaresWhenIll, "otherValue:" + trimmedValue]);
    }
  }
  

  const handleOtherWorkModeValueChange = (event) => {
    setOtherworkMode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      workMode: false,
    }));
  };

  const handleEnvironmentStep1 = () => {
    const errors = {};

    if (applies === "") {
      errors.applies = true;
    }
    if (highestLevelOfEducation === "") {
      errors.highestLevelOfEducation = true;
    }
    if (freeFromPsychologicalStress == null) {
      errors.freeFromPsychologicalStress = true;
    }
    if (cleanAir == null) {
      errors.cleanAir = true;
    }
    if (pollutedAir == null) {
      errors.pollutedAir = true;
    }
    if (timeSpentInTraffic === "") {
      errors.timeSpentInTraffic = true;
    }
    if (timeSpentOnLaptop === "") {
      errors.timeSpentOnLaptop = true;
    }
    console.log("errrrr", errors)
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        workProfile: applies,
        highestLevelOfEducation: highestLevelOfEducation,
        freeFromPsychologicalStress: freeFromPsychologicalStress,
        cleanAir: cleanAir,
        pollutedAir: pollutedAir,
        occupation: occupation,
        timeSpentInTraffic: timeSpentInTraffic,
        timeSpentOnLaptop: timeSpentOnLaptop,
        familyMembers: familyMembers ? familyMembers.join(";") : familyMembers,
        takeCaresWhenIll: takeCaresWhenIll ? takeCaresWhenIll.join(";") : takeCaresWhenIll,
        livingAccommodation: accommodation,
        fellDepressed: depressed,
        intensityOfDepression: intensityOfDepression,
        timeSpentOnMobile: timeSpentOnMobile,
        timeSpentOnTv: timeSpentOnTv,
      };

      console.log("submitted", formData);
      AxiosInstance.post("form/addEnvironmentSketch", formData)
        .then((response) => {
          console.log("response", response);
        })
        .catch((error) => {
          console.log(error);
        });
       setEnvironmentStep((prev) => prev + 1);
    }
   
  };

  const handleEnvironmentStep2 = () => {
    const errors2 = {};

    if (familyMembers === "" || familyMembers.length === 0) {
      errors2.familyMembers = true;
    }
    if (takeCaresWhenIll === "" || takeCaresWhenIll.length === 0) {
      errors2.takeCaresWhenIll = true;
    }
    if (accommodation === "") {
      errors2.accommodation = true;
    }
    if (livingArrangement === "") {
      errors2.livingArrangement = true;
    }
    setFieldErrors2(errors2);
    console.log('errors2: ', errors2);
    if (Object.keys(errors2).length > 0) {
       (
        (Array.isArray(familyMembers) && familyMembers.length === 0) ||
        (Array.isArray(takeCaresWhenIll) && takeCaresWhenIll.length === 0) ||
        accommodation == "" ||
        livingArrangement == ""
      )
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        workProfile: applies,
        highestLevelOfEducation: highestLevelOfEducation,
        employmentStatus: employmentStatus,
        freeFromPsychologicalStress: freeFromPsychologicalStress,
        cleanAir: cleanAir,
        pollutedAir: pollutedAir,
        occupation: occupation,
        timeSpentInTraffic: timeSpentInTraffic,
        timeSpentOnLaptop: timeSpentOnLaptop,
        familyMembers: familyMembers ? familyMembers.join(";") : familyMembers,
        takeCaresWhenIll: Array.isArray(takeCaresWhenIll) ? takeCaresWhenIll.join(";") : takeCaresWhenIll,
        livingAccommodation: accommodation,
        fellDepressed: depressed,
        intensityOfDepression: intensityOfDepression,
        timeSpentOnMobile: timeSpentOnMobile,
        timeSpentOnTv: timeSpentOnTv,
      };

      console.log("submitted", formData);
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {
      //     console.log("response", response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };

  const handleEnvironmentStepBack = () => {
    setEnvironmentStep((prev) => prev - 1);
  };

  const onEnvironmentSubmit = (e) => {
    e.preventDefault();
    const errors3 = {};
    if (depressed === "") {
      errors3.depressed = true;
    }
    if (timeSpentOnMobile === "") {
      errors3.timeSpentOnMobile = true;
    }
    if (timeSpentOnTv === "") {
      errors3.timeSpentOnTv = true;
    }
    if (intensityOfDepression === "") {
      errors3.intensityOfDepression = true;
    }
    setFieldErrors3(errors3);
    if (depressed == "" || timeSpentOnMobile == "" || timeSpentOnTv == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
      if (depressed == true) {
        if (intensityOfDepression == "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Fill all the Mandatory Fields!",
          });
        }
      }
    } else {
      const formData = {
        workProfile: applies,
        highestLevelOfEducation: highestLevelOfEducation,
        employmentStatus: employmentStatus,
        freeFromPsychologicalStress: freeFromPsychologicalStress,
        cleanAir: cleanAir,
        pollutedAir: pollutedAir,
        occupation: occupation,
        timeSpentInTraffic: timeSpentInTraffic,
        timeSpentOnLaptop: timeSpentOnLaptop,
        familyMembers: familyMembers ? familyMembers.join(";") : familyMembers,
        takeCaresWhenIll: takeCaresWhenIll ? takeCaresWhenIll.join(";") : takeCaresWhenIll,
        livingAccommodation: accommodation,
        fellDepressed: depressed,
        intensityOfDepression: intensityOfDepression,
        timeSpentOnMobile: timeSpentOnMobile,
        timeSpentOnTv: timeSpentOnTv,
      };
      console.log("submitted", formData);
      AxiosInstance.post("form/addEnvironmentSketch", formData)
        .then((response) => {
          console.log("response", response);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Form Submitted Successfully!",
          });
          setTimeout(() => {
            onBack();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let lastValue1;
  let value1;
  useEffect(() => {
    AxiosInstance.get("form/environmentSketch")
      .then((response) => {
        console.log('response: ', response);
        const match = response.familyMembers.match(/OtherValue:([^;\s]+)/g);
        if (match) {
          lastValue1 = match[match.length - 1];
          value1 = lastValue1.slice(11);
          setOtherFamilyValue(value1);
        } else {
          console.log("No value found");
        }
        setOtherApplies(response.workProfile);
        setOtherworkMode(response.workMode);
        setOtherValue(response.livingAccommodation);
        setApplies(response?.workProfile ? response.workProfile : applies);
        sethighestLevelOfEducation(
          response?.highestLevelOfEducation
            ? response.highestLevelOfEducation
            : highestLevelOfEducation
        );
        setemploymentStatus(
          response?.employmentStatus
            ? response.employmentStatus
            : employmentStatus
        );
        setfreeFromPsychologicalStress(
          response?.freeFromPsychologicalStress !== null
            ? response.freeFromPsychologicalStress
            : freeFromPsychologicalStress
        );
        setcleanAir(response?.cleanAir !== null ? response.cleanAir : cleanAir);
        setpollutedAir(
          response?.pollutedAir !== null ? response.pollutedAir : pollutedAir
        );
        setoccupation(response?.occupation ? response.occupation : occupation);
        settimeSpentInTraffic(
          response?.timeSpentInTraffic
            ? response.timeSpentInTraffic
            : timeSpentInTraffic
        );
        // setworkMode(response?.workMode ? response.workMode : workMode);
        settimeSpentOnLaptop(
          response?.timeSpentOnLaptop
            ? response.timeSpentOnLaptop
            : timeSpentOnLaptop
        );
        setFamilyMembers(
          response?.familyMembers
            ? response.familyMembers.split(";")
            : familyMembers
        );
        settakeCaresWhenIll(
          response?.takeCaresWhenIll
            ? response.takeCaresWhenIll
            : takeCaresWhenIll
        );
        setAccommodation(
          response?.livingAccommodation
            ? response.livingAccommodation
            : accommodation
        );
        setdepressed(
          response?.fellDepressed ? response.fellDepressed : depressed
        );
        setintensityOfDepression(
          response?.intensityOfDepression
            ? response.intensityOfDepression
            : intensityOfDepression
        );
        settimeSpentOnMobile(
          response?.timeSpentOnMobile
            ? response.timeSpentOnMobile
            : timeSpentOnMobile
        );
        settimeSpentOnTv(
          response?.timeSpentOnTv ? response.timeSpentOnTv : timeSpentOnTv
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const getRelationship = () => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response?.relationShipWithUser);
        setFamilyInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRelationship();
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyAge(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>
    {familyRelation != "wife" && familyRelation != null && (
            <>
              <h4 style={{ color: "blue" }}>
                The health Insights/Information presented below is for your child{" "}
                <span style={{ fontSize: "xx-large" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
              </h4>
              <h4 style={{ color: "blue" }}>
                Please fill this form for your child{" "}
                <span style={{ fontSize: "xx-large" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
              </h4>
              <br />
            </>
          )}
      {environmentStep == 0 && (
        <Grid container spacing={2} mb={5}>
          <Grid item>
            <div className="register_title">
              <h2>Environment Sketch</h2>
              {/* <h5>Tier 2</h5> */}
            </div>
          </Grid>
        </Grid>
      )}

      <form onSubmit={onEnvironmentSubmit} className="registration_tier1">
        {environmentStep == 0 && (
          //&& age >= 18
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>1. Student Life/Employment/Work Environment </h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ color: fieldErrors.applies ? "red" : "inherit" }}
                  >
                    {/* <span className={applies ? "" : "mandatoryQues"}> */}
                    Which of these applies to <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span> :{/* </span> */}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={applies}
                    onChange={handleAppliesChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Working professional"
                      control={<Radio />}
                      label="Working professional"
                    />
                    <FormControlLabel
                      value="Student"
                      control={<Radio />}
                      label="Student"
                    />
                    <FormControlLabel
                      value="Home maker"
                      control={<Radio />}
                      label="Home maker"
                    />
                    <FormControlLabel
                      value="Retired"
                      control={<Radio />}
                      label="Retired"
                    />
                    {/* <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    /> */}
                  </RadioGroup>
                  {/* Render input field for "Other" only if "Other" is selected */}
                  {console.log("otherApplies", otherApplies)}
                  {/* {(applies === "Other") && ( */}
                  Other:{" "}
                  <input
                    type="text"
                    placeholder="Other Applies to you:"
                    value={otherApplies}
                    onChange={handleOtherAppliesValueChange}
                    onBlur={handleInputBlur}
                  />
                  {/* )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.highestLevelOfEducation
                        ? "red"
                        : "inherit",
                    }}
                  >
                    Highest level of education completed :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={highestLevelOfEducation}
                    name="radio-buttons-group"
                    onChange={handlehighestLevelOfEducationChange}
                  >
                    <FormControlLabel
                      value="Grade"
                      control={<Radio />}
                      label="Grade"
                    />
                    <FormControlLabel
                      value="High School"
                      control={<Radio />}
                      label="High School"
                    />
                    <FormControlLabel
                      value="2 Year Degree"
                      control={<Radio />}
                      label="2 Year Degree"
                    />
                    <FormControlLabel
                      value="4 Year Degree"
                      control={<Radio />}
                      label="4 Year Degree"
                    />
                    <FormControlLabel
                      value="Graduate Degree"
                      control={<Radio />}
                      label="Graduate Degree"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.freeFromPsychologicalStress
                        ? "red"
                        : "inherit",
                    }}
                  >
                    I am quite happy and contented in life and free from any
                    kind of Psychological stress.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={freeFromPsychologicalStress}
                    onChange={handlefreeFromPsychologicalStressChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.cleanAir ? "red" : "inherit",
                    }}
                  >
                    Where I live, the air is relatively clean and free from dust
                    or chemicals.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={cleanAir}
                    onChange={handlecleanAirChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.pollutedAir ? "red" : "inherit",
                    }}
                  >
                    Where I live, the air is not so polluted.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={pollutedAir}
                    onChange={handlepollutedAirChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.timeSpentInTraffic ? "red" : "inherit",
                    }}
                  >
                    How much time do you spend in traffic every day to go to
                    workplace or place of study ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentInTraffic}
                    onChange={handletimeSpentInTrafficChange}
                  >
                    <FormControlLabel
                      value="< 15 minutes"
                      control={<Radio />}
                      label="< 15 minutes"
                    />
                    <FormControlLabel
                      value="15-30 minutes"
                      control={<Radio />}
                      label="15-30 minutes"
                    />
                    <FormControlLabel
                      value="30-60 minutes"
                      control={<Radio />}
                      label="30-60 minutes"
                    />
                    <FormControlLabel
                      value="More than an hour"
                      control={<Radio />}
                      label="More than an hour"
                    />
                    <FormControlLabel
                      value="Does not apply."
                      control={<Radio />}
                      label="Does not apply."
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.timeSpentOnLaptop ? "red" : "inherit",
                    }}
                  >
                    How many hours do you typically spend daily on a laptop for
                    your work (for students and working professionals only) ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentOnLaptop}
                    onChange={handletimeSpentOnLaptopChange}
                  >
                    <FormControlLabel
                      value="< 1"
                      control={<Radio />}
                      label="< 1"
                    />
                    <FormControlLabel
                      value="1-2"
                      control={<Radio />}
                      label="1-2"
                    />
                    <FormControlLabel
                      value="2-4"
                      control={<Radio />}
                      label="2-4"
                    />
                    <FormControlLabel
                      value="4-6"
                      control={<Radio />}
                      label="4-6"
                    />
                    <FormControlLabel
                      value="6-8"
                      control={<Radio />}
                      label="6-8"
                    />
                    <FormControlLabel
                      value=">10"
                      control={<Radio />}
                      label=">10"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid
                container
                spacing={0}
                mb={0}
                mt={3}
                justifyContent="space-between"
                display={"flex"}
                gap={5}
              >
                <div className="submit-button">
                  <button className="base" onClick={onBack}>
                    Back
                  </button>
                </div>
                <div className="submit-button">
                  <button
                    type="button"
                    className="base"
                    onClick={handleEnvironmentStep1}
                  >
                    Next
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}

        {environmentStep == 1 && (
          // && relationship != "son" || relationship != "daughter"
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. Family and Other Relations </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.familyMembers ? "red" : "inherit",
                    }}
                  >
                    Who all are in your family (living with you)?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Mother")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Mother"
                      value="Mother"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Father")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Father"
                      value="Father"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Brother")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Brother"
                      value="Brother"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Sister")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Sister"
                      value="Sister"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Grand Parents")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Grand Parents"
                      value="Grand Parents"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Other")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Other"
                      value="Other"
                    />
                  </FormGroup>

                  {familyMembers.includes("Other") && (
                    <input
                      type="text"
                      value={otherFamilyValue}
                      onChange={handleOtherFamilyValueChange}
                      onBlur={handleInputBlurFamily}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <label
                  style={{
                    color: fieldErrors2.takeCaresWhenIll ? "red" : "inherit",
                  }}
                >
                  Who takes care of you when you are ill ?
                </label>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={takeCaresWhenIll.includes("Mother")}
                        onChange={handletakeCaresWhenIllChange}
                      />
                    }
                    label="Mother"
                    value="Mother"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={takeCaresWhenIll.includes("Father")}
                        onChange={handleTakeCareWillIll}
                      />
                    }
                    label="Father"
                    value="Father"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={takeCaresWhenIll.includes("Brother")}
                        onChange={handleTakeCareWillIll}
                      />
                    }
                    label="Brother"
                    value="Brother"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={takeCaresWhenIll.includes("Sister")}
                        onChange={handleTakeCareWillIll}
                      />
                    }
                    label="Sister"
                    value="Sister"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={takeCaresWhenIll.includes("Self")}
                        onChange={handleTakeCareWillIll}
                      />
                    }
                    label="Self"
                    value="Self"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={takeCaresWhenIll.includes("Other")}
                        onChange={handleTakeCareWillIll}
                      />
                    }
                    label="Other"
                    value="Other"
                  />
                </FormGroup>

                {takeCaresWhenIll.includes("Other") && (
                  <input
                    type="text"
                    value={otherFamilyValue}
                    onChange={handletakeCaresWhenIllChange}
                    onBlur={handleInputBlurFamily}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.accommodation ? "red" : "inherit",
                    }}
                  >
                    Living Accommodation :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={accommodation}
                    onChange={handleAccommodationChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="House"
                      control={<Radio />}
                      label="House"
                    />
                    <FormControlLabel
                      value="Apartment"
                      control={<Radio />}
                      label="Apartment"
                    />
                    {/* <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    /> */}
                  </RadioGroup>
                  {/* Render input field for "Other" only if "Other" is selected */}
                  {/* {accommodation === "Other" && ( */}
                  Other:{" "}
                  <input
                    type="text"
                    placeholder="Accomadation"
                    value={otherValue}
                    onChange={handleOtherValueChange}
                    onBlur={handleInputBlurChildren}
                  />
                  {/* )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.livingArrangement ? "red" : "inherit",
                    }}
                  >
                    Living Arrangement :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={livingArrangement}
                    onChange={handlelivingArrangementChange}
                  >
                    <FormControlLabel
                      value="Alone"
                      control={<Radio />}
                      label="Alone"
                    />
                    <FormControlLabel
                      value="With family/friends"
                      control={<Radio />}
                      label="With family/friends"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid
                container
                spacing={0}
                mb={0}
                mt={3}
                justifyContent="space-between"
                display={"flex"}
                gap={5}
              >
                <div className="submit-button">
                  <button className="base" onClick={handleEnvironmentStepBack}>
                    Back
                  </button>
                </div>
                <div className="submit-button">
                  <button
                    type="button"
                    className="base"
                    onClick={handleEnvironmentStep2}
                  >
                    Next
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}

        {environmentStep == 2 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>3. Optional Questions </h4>
                  <p>
                    The following questions are optional. You may choose not to
                    answer but answering them may help us give you better
                    insight into your Child's chronic diseases risk evaluation.{" "}
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.depressed ? "red" : "inherit",
                    }}
                  >
                    Do you ever feel depressed ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={depressed}
                    onChange={handledepressedChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {depressed === "yes" && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors3.intensityOfDepression
                          ? "red"
                          : "inherit",
                      }}
                    >
                      If you ever feel depressed then what is your level of
                      intensity of depression (1 = low , 5 = high) ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={intensityOfDepression}
                      onChange={handleintensityOfDepressionChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="2"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="3"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="4"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="5"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.timeSpentOnMobile ? "red" : "inherit",
                    }}
                  >
                    How many hours do you think you typically spend daily on
                    your mobile/cell ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentOnMobile}
                    onChange={handletimeSpentOnMobileChange}
                  >
                    <FormControlLabel
                      value="<1"
                      control={<Radio />}
                      label="<1"
                    />
                    <FormControlLabel
                      value="1-2"
                      control={<Radio />}
                      label="1-2"
                    />
                    <FormControlLabel
                      value="2-4"
                      control={<Radio />}
                      label="2-4"
                    />
                    <FormControlLabel
                      value="4-6"
                      control={<Radio />}
                      label="4-6"
                    />
                    <FormControlLabel
                      value=">6 hours"
                      control={<Radio />}
                      label=">6 hours"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.timeSpentOnTv ? "red" : "inherit",
                    }}
                  >
                    How many hours do you spend watching TV on a weekly basis ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentOnTv}
                    onChange={handletimeSpentOnTvChange}
                  >
                    <FormControlLabel
                      value="<1"
                      control={<Radio />}
                      label="<1"
                    />
                    <FormControlLabel
                      value="1-3"
                      control={<Radio />}
                      label="1-3"
                    />
                    <FormControlLabel
                      value="3-5"
                      control={<Radio />}
                      label="3-5"
                    />
                    <FormControlLabel
                      value="5-7"
                      control={<Radio />}
                      label="5-7"
                    />
                    <FormControlLabel
                      value="7-10"
                      control={<Radio />}
                      label="7-10"
                    />
                    <FormControlLabel
                      value=">10"
                      control={<Radio />}
                      label=">10 hours"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="submit" className="base">
                  Submit
                </button>
              </div>
            </Grid>
          </>
        )}
      </form>
    </div>
  );
};

export default ChildrenEnvironment13to21Questions;
