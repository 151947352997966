import {
  Button, Card, CardHeader, CardText, CardTitle, Col, Row,
  Modal,
  ModalBody,
} from "reactstrap";
import { useEffect, useState } from "react";
import { deleteTests, getBuildPackageData, getSaveLaterData, getStripePaymentLink, saveLaterToggle } from "../../constants/apiList";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import './TestCart.css';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import { MdOutlineShoppingCart } from "react-icons/md";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import SaveItem from "../SaveForLater/SaveItem";
import { useCart } from './CartContext'; 
const TestRequestForm = ({ setCurrentTabIndex, setForModelOpen }) => {
  const { refresh, triggerRefresh } = useCart();
  const [testLabOrderList, setTestLabOrderList] = useState({});
  const [isReload, setIsReload] = useState(false)
  const [buildPackage, setBuildPackage] = useState({});
  const getBuildPackageDate = async () => {
    await AxiosInstance.get(`${getBuildPackageData}`)
      .then((response) => {
        if (response != null) {
          setBuildPackage(response);
          setTestLabOrderList(response.testLabCartList);
          setOpen(response.testLabCartList.length > 0 ? response.testLabCartList[0].cartId : null)
          setOpenModel(response.testLabCartList.length > 1 ? true : false);
          triggerRefresh();
        } else {
          setBuildPackage('');
          setTestLabOrderList('');
          setOpen('')
          setOpenModel('');
          triggerRefresh();

        }
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [openModel, setOpenModel] = useState(true);
  const [focusAfterClose, setFocusAfterClose] = useState(true);

  const toggleModel = () => setOpenModel(!openModel);

  const calculateTotalPayment = () => {
    if (!Array.isArray(testLabOrderList) || testLabOrderList.length === 0) {
      return { orderId: null, totalPayment: 0 };
    }
    const orderId = testLabOrderList[0].orderId;
    const totalPayment = testLabOrderList.reduce((total, item) => {
      return total + (parseFloat(item.testPrice) || 0);
    }, 0);
    return {orderId, totalPayment };
  };
  const {orderId, totalPayment} = calculateTotalPayment();
  const [paymentRenderLink, setPaymentRenderLink] = useState();
  const handleSubmit = (Price, cartId) => {
    // event.preventDefault();
    const CancelUrl = 'https://' + `dev.livingscopehealth.in`+ '/lab-services?page=testcart';
    const successURL =  'https://' + `dev.livingscopehealth.in` +'/success';

    // const CancelUrl = 'http://' + "localhost:1234" + '/lab-services?page=testcart';
    // const successURL = 'http://' + "localhost:1234/success";
    const amount = Price ? Price : totalPayment;
    AxiosInstance.get(`${getStripePaymentLink}?successURL=${successURL}&cancelURL=${CancelUrl}&amount=${amount}&cartId=${cartId}`)
      .then((response) => {
        setPaymentRenderLink(response);
        window.location.href = response;
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }
  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const deleteTestsDetails = (cartId, testName) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to remove this test from Cart ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          AxiosInstance.put(`${deleteTests}/${cartId}/${testName}`)
            .then((response) => {
              triggerRefresh();
              toast.success(response.message);
              setIsReload(!isReload);
            })
            .catch((err) => {
              console.log('err: ', err);
            });
        }
      });
  }
  const saveForLater = (cartId, testName) => {
    swalWithBootstrapButtons
    .fire({
      title: "Are you sure?",
      text: `Do you want to save for later?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Save it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        AxiosInstance.put(`${saveLaterToggle}/${cartId}/${testName}`)
          .then((response) => {
            toast.success(response.message)
            triggerRefresh();
            setIsReload(!isReload);
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      }
    });
}
const handleSaveForLater = () => {
  setIsReload(!isReload);
};
  useEffect(() => {
    getBuildPackageDate();
  }, [isReload]);
  return (
    <>
      <div>
        <Modal returnFocusAfterClose={focusAfterClose} isOpen={openModel}>
          <ModalBody>
            <div className="card-container mt-4">
              <Row >
                {testLabOrderList && testLabOrderList.length > 0 ? (
                  <Col sm="12">
                    <CardHeader className="mb-4">
                      <center><b>Cart ID: </b>
                        {testLabOrderList.map((test, index) => (
                          <span><b>{index + 1}  {index < testLabOrderList.length - 1 ? ' & ' : ''}</b></span>
                        ))}
                      </center>
                    </CardHeader>
                  </Col>
                ) : ('')}
                {testLabOrderList && testLabOrderList.length > 0 ? (
                  testLabOrderList.map((test, index) => (
                    <Col sm="6" key={index}>
                      <Card body className="cardBodyCustome">
                        <CardText className="mb-1" ><b>Test Name : </b>{test.testName}</CardText>
                        <CardText className="mb-1" ><b>LAB NAME : </b>{test.labName}</CardText>
                      </Card>
                    </Col>
                  ))
                ) : ('')}
                <Col sm="12">
                  <div className="p-3">
                    <b>Note : </b> Your order will be processed through two of our partners as shown above as some of
                    the tests are not available through “ XYZ”.  You will be presented with two order forms so that
                    you can schedule for you preferred date & time with each of our Lab Partners. However, you
                    will need to make only one total payment at the end of the second order.
                    <div className="mt-2"><b>TOTAL PAYMENT = {totalPayment} INR (You will save 20-25%)</b></div>
                  </div>
                  <center>
                    <Button className="mb-2" color="primary" onClick={toggleModel}>
                      Ok
                    </Button>
                  </center>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </div>
      {testLabOrderList && testLabOrderList.length > 0 ? (<>
        <Card
          style={{
            padding: "15px",
            border: "1px solid black",
            margin: "10px 0px 10px 0px",
          }}
          elevation={5}
        >{buildPackage && (
          <>
            <Row>
              <Col>
                <div><b>Patient Contact No : </b>{buildPackage.patientContactNumber}</div>
              </Col>
              <Col lg={6}>
                <div><b>Date : </b>{buildPackage.date}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div><b>UHID- </b>{buildPackage.patientUpId}</div>
              </Col>
              <Col lg={6}>
                <div><b>Age : </b>{buildPackage.patientAge}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div><b>Patient Name : </b>{buildPackage.patientName}</div>
              </Col>
              <Col lg={6}>
                <div><b>Gender : </b>{buildPackage.patientGender}</div>
              </Col>
            </Row>
          </>)}
        </Card>
        <div className="mt-4">
          <Accordion open={open} toggle={toggle}>
            {testLabOrderList && testLabOrderList.length > 0 ? (
              testLabOrderList.map((test, index) => (
                <AccordionItem>
                  <AccordionHeader targetId={test.cartId}><span className="orderIdFontSize">Cart ID : {index + 1}</span></AccordionHeader>
                  <AccordionBody accordionId={test.cartId}>
                    <Card className="disableBorderInCard" body>
                      <Row>
                        <Col lg={test.lrid ? 6 : 12}>
                          <CardTitle className="mb-1"><b>Test Name : </b>{test.testName}</CardTitle>
                        </Col>
                        <Col lg={6}>
                          {test.lrid && (
                            <CardTitle className="mt-1"><b>Lrid : </b> {test.lrid}</CardTitle>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <CardText className="mt-1"><b>Lab Name : </b>{test.labName}</CardText>
                          <CardText className="mt-1" ><b>Test Price : </b>{test.testPrice} INR</CardText>
                        </Col>
                        <Col lg={6}>
                          <CardText className="mt-1"><b>Lab Address : </b> {test.labName + ' ' + test.labAddress}</CardText>
                          <CardText className="mt-1"><b>Lab Contact : </b>{test.labPhoneNumber}</CardText>
                        </Col>
                        <Col lg={6}>
                          {/* Displaying testDetail items */}
                          <div className="mt-1">
                            <b>Test Details:</b>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th><b>Sr no.</b></th>
                                  <th><b>Test Name</b></th>
                                  <th><b>Test Price</b></th>
                                  <th><b>Action</b></th>
                                </tr>
                              </thead>
                              <tbody>
                                {test.testDetail.map((detail, idx) => (
                                  <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{detail.testName}</td>
                                    <td>{detail.testPrice ? `${detail.testPrice} INR` : 'Price not available'}</td>
                                    <td><span className="paynow-btn cart-icon-btn m-2" onClick={() => saveForLater(test.cartId, detail.testName)}>Saved for later </span>
                                    <span className="delete-btn cart-icon-btn" onClick={() => deleteTestsDetails(test.cartId, detail.testName)}>Delete</span></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-1">
                        <b>Preferred Date and Time : </b>
                        <input type="date" style={{ margin: "0px 10px 0px 10px" }}></input>
                        <input type="time"></input>
                      </div>
                      <span className="mt-4" ><b>Note : </b> Tests should include Total Cholesterol, LDL Cholesterol, HDL
                        Cholesterol, Triglyceride, Very Low-Density Lipoprotein, Non-HDL
                        Cholesterol.</span>
                    </Card>
                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                      <span className="payNow-btn cart-icon-btn mr-2" onClick={() => handleSubmit(test.testPrice, test.cartId)}>Pay for cart</span>
                      {/* <span className="SFL-btn cart-icon-btn" onClick={() => saveForLater(test.cartId)}>Save for later</span> */}
                    </div>
                  </AccordionBody>
                </AccordionItem>))) : ('')}
          </Accordion>
          <div className="mt-2"><b>TOTAL PAYMENT = {totalPayment} INR</b></div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              style={{
                background: "#0087ff",
                color: "#fff",
                minWidth: "100px",
                "&:hover": { background: "#1960a7" },
              }}
              onClick={() => handleSubmit(totalPayment, buildPackage.cartIds)}
              type="button"
            >
              Pay Now {testLabOrderList.length > 1 ? `(All carts)`: ''}
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div style={{ textDecoration: "underline" }}>
            <b>Notes to Lab: </b>
          </div>
          <ul style={{ listStyle: "auto", padding: "15px" }}>
            <li>Please call the patient promptly to set up home collection.</li>
            <li>
              After tests are done and reports are available, please upload on
              Uncurl: health app platform mentioning Patient ID.
            </li>
            <li>
              If there are any questions or issues regarding this order, call our
              customer support team
            </li>
            <li>
              Please send bill reflecting Living Scope Order ID as well as Patient
              ID.
            </li>
          </ul>
        </div>
        <div>
          <div>
            <b>Note to Patient: </b>
          </div>
          <div>
            You can wait for a call or message confirmation from the Lab or
            alternatively call the Lab directly to confirm your appointment. Call
            them at. Don’t forget to mention Living Scope Health Order ID as well
            as your UHID with us to help them find your appointment.
          </div>
        </div>
      </>) : (<>
        <div className="mt-4">
          <Row>
            <Col sm="4">
              <div className="empty-card">
                <div className="for-border-radius">
                  <MdOutlineShoppingCart className="empty-cart-icon" />
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="empty-cart-box">
                <p className="empty-cart-text">Your Cart is <span className="empty-cart-text-empty">Empty!</span></p>
                <NavLink to={"/preventive-care"}>
                  <a className="empty-cart-recommendations">See preventive recommendations</a>
                </NavLink>
              </div>
            </Col>
          </Row>
        </div>
  
          <div className="mt-4">
            <SaveItem isVisible={true} onRefresh={handleSaveForLater} />
          </div>
       
      </>
      )}
    </>
  );
};

export default TestRequestForm;
