import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Tooltip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import { Col, Row } from "reactstrap";

// Styled component for highlighted card
const HighlightedCard = styled(Card)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#f0f0f0" : "#fff",
  border: isSelected ? "2px solid #3f51b5" : "1px solid #ccc",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  height: "100%",
  flexGrow: 1,
}));

const NutritionistCard = ({ nutritionistData, onDoctorSelect }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleCardClick = (doctor) => {
    setSelectedDoctor(doctor); // Set selected doctor locally
  };

  const handleBookHereClick = () => {
    if (selectedDoctor) {
      onDoctorSelect(selectedDoctor); // Send selected doctor data to parent component
    }
  };

  const truncateBio = (bio, maxLength) => {
    if (bio.length > maxLength) {
      return bio.substring(0, maxLength) + "...";
    }
    return bio;
  };

  const getAvatarColor = (id) => {
    const colors = ["#FFC300", "#33A1FD", "#DAF7A6", "#FF33F6", "#33FF95"];
    return colors[id % colors.length]; // Cycles through the colors array
  };

  return (
    <div>
      <Row className="d-flex flex-column">
        {nutritionistData?.map((doctor) => (
          <Col key={doctor?.userId} className="mb-4 ">
            <HighlightedCard
              isSelected={selectedDoctor?.userId === doctor?.userId}
              onClick={() => handleCardClick(doctor)}
              sx={{ maxWidth: "100%", minHeight: "350px" }}
            >
              <CardContent>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid item>
                    <Avatar
                      sx={{
                        bgcolor: getAvatarColor(doctor?.userId), // Dynamic background color
                        color: "white", // Text color
                      }}
                    >
                      {doctor?.firstName?.[0] || ""}
                      {doctor?.lastName?.[0] || ""}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {doctor?.firstName} {doctor?.lastName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {/* Left Column */}
                  <Grid item xs={12} md={6}>
                    <Tooltip title={doctor?.bio} arrow>
                      <Typography
                        variant="body1"
                        style={{
                          marginBottom: "8px",
                          fontSize: "1.1rem",
                          cursor: "pointer",
                          display: "-webkit-box",
                          WebkitLineClamp: 3, // Limit to 3 lines
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <strong>Bio:</strong> {truncateBio(doctor?.bio, 100)}
                      </Typography>
                    </Tooltip>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Google Rating:</strong>{" "}
                      {doctor?.googleRating || "N/A"}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Self-Services Rating:</strong>{" "}
                      {doctor?.selfRating || "N/A"}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Licensed/Registered Dietician:</strong> Yes
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Education Qualifications:</strong>{" "}
                      {doctor?.educationQualifications}
                    </Typography>
                    {doctor.yearsOfExperience && (
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "8px" }}
                      >
                        <strong>Total Years Of Experience:</strong>{" "}
                        {doctor?.yearsOfExperience}
                      </Typography>
                    )}
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Ever Worked As Clinical Dietitian ?</strong>{" "}
                      {doctor?.everWorkedAsClinicalDietitian}
                    </Typography>
                    {doctor?.everWorkedAsClinicalDietitian === "Yes" && (
                      <div
                        style={{
                          marginLeft: "20px",
                          paddingLeft: "10px",
                          borderLeft: "2px solid #ccc",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "8px" }}
                        >
                          <strong>Name of Hospital:</strong>{" "}
                          {doctor?.hospitalName || "N/A"}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "8px" }}
                        >
                          <strong>Years of Experience:</strong>{" "}
                          {doctor?.yearsOfExperience || "N/A"}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "8px" }}
                        >
                          <strong>Specialization:</strong>{" "}
                          {doctor?.specialization || "N/A"}
                        </Typography>
                      </div>
                    )}
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>
                        Expert Dietician, helping clients with following
                        diseases:{" "}
                      </strong>
                      {doctor?.expertiseDiseases || "CVD, Hypertension, CKD"}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Age Group qualified for:</strong>{" "}
                      {doctor?.clientAgeGroupExperience ||
                        "3 and more (including seniors)"}
                    </Typography>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        marginBottom: "16px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        border: "1px solid black",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          marginBottom: "8px",
                          color: "#1976d2",
                          textAlign: "center", // Center the title
                          backgroundColor: "#e3f2fd", // Light background for the title
                          padding: "8px",
                          borderRadius: "8px 8px 0 0", // Rounded corners on top
                        }}
                      >
                        Experience Categories
                      </Typography>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "0 0 8px 8px", // Rounded corners on bottom
                          backgroundColor: "#ffffff", // White background for the grid
                          padding: "8px",
                        }}
                      >
                        {/* Disease Column */}
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            style={{
                              marginBottom: "4px",
                              fontWeight: "bold",
                              color: "#333",
                            }} // Dark text color for readability
                          >
                            Disease:
                          </Typography>
                          <Typography variant="body1">
                            {doctor?.diseaseExperience || "CVD"}
                          </Typography>
                        </Grid>

                        {/* Client Age Group Column */}
                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                            style={{
                              marginBottom: "4px",
                              fontWeight: "bold",
                              color: "#333",
                            }} // Dark text color for readability
                          >
                            Client Age Group:
                          </Typography>
                          <Typography variant="body1">
                            {doctor?.clientAgeGroup || ">21"}
                          </Typography>
                        </Grid>

                        {/* Number of Clients Helped Column */}
                        <Grid item xs={5}>
                          <Typography
                            variant="body1"
                            style={{
                              marginBottom: "4px",
                              fontWeight: "bold",
                              color: "#333",
                            }} // Dark text color for readability
                          >
                            Number of Clients Helped So Far:
                          </Typography>
                          <Typography variant="body1">
                            {doctor?.numberOfClientsHelped || "100"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>

                    {/* Expert Dietician Section */}

                    {/* Client Testimonials Section */}
                    <div
                      style={{
                        marginBottom: "16px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        border: "1px solid black",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          border: "1px solid #ccc",
                          color: "#1976d2",
                          textAlign: "center", // Center the title
                          backgroundColor: "#e3f2fd", // Light background for the title
                          padding: "8px",
                          borderRadius: "8px 8px 0 0", // Rounded corners on top
                        }}
                      >
                        Here is what Clients are saying about{" "}
                        {doctor?.firstName} (References)
                      </Typography>
                      <div
                        style={{
                          backgroundColor: "#ffffff", // White background for the testimonials
                          padding: "16px",
                          borderRadius: "0 0 8px 8px", // Rounded corners on bottom
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            marginBottom: "8px",
                            fontStyle: "italic",
                            color: "#555",
                          }}
                        >
                          1. {doctor?.testimonial1 || "Client Reference 1"}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            marginBottom: "8px",
                            fontStyle: "italic",
                            color: "#555",
                          }}
                        >
                          2. {doctor?.testimonial2 || "Client Reference 2"}
                        </Typography>
                      </div>
                    </div>

                    {/* <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      1. {doctor?.testimonial1 || "Client Reference 1"}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      2. {doctor?.testimonial2 || "Client Reference 2"}
                    </Typography> */}

                    <Typography variant="body1">
                      <span
                        style={{
                          color: "#1976d2",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          // Logic to show more testimonials
                          alert("Displaying more testimonials..."); // You can replace this with actual logic to display testimonials.
                        }}
                      >
                        Click here{" "}
                      </span>
                      to read {doctor?.firstName}'s client testimonials.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginRight: "16px", // Space between items
                        color: "#3f51b5", // Color for audio consultation charge
                        fontSize: "16px", // Adjust font size
                        fontWeight: "bold",
                      }}
                    >
                      Audio Charge: ₹{doctor?.audioAmount || "N/A"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        marginRight: "16px", // Space between items
                        color: "#4caf50", // Color for video consultation charge
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Video Charge: ₹{doctor?.videoAmount || "N/A"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#f44336", // Color for chat consultation charge
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Chat Charge: ₹{doctor?.chatAmount || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </HighlightedCard>
          </Col>
        ))}
      </Row>

      {/* {selectedDoctor && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBookHereClick}
          >
            Book Here
          </Button>
        </div>
      )} */}
      {selectedDoctor && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            gap: "10px", // Space between the buttons
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3f51b5", // Color for Audio Booking (matches from previous card colors)
              color: "#ffffff",
            }}
            onClick={() => handleBookHereClick("audio")}
          >
            Book Audio Here
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#4caf50", // Color for Video Booking
              color: "#ffffff",
            }}
            onClick={() => handleBookHereClick("video")}
          >
            Book Video Here
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#f44336", // Color for Chat Booking
              color: "#ffffff",
            }}
            onClick={() => handleBookHereClick("chat")}
          >
            Book Chat Here
          </Button>
        </div>
      )}
    </div>
  );
};

export default NutritionistCard;
