import React, { useEffect, useState } from "react";
import { Col, List, Row } from "reactstrap";
import { CiFaceSmile } from "react-icons/ci";
import ReactSpeedometer from "react-d3-speedometer";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import "./HealthCardReport.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getKidneyRiskAssessment, getLifestyleRiskAssessmentApi, getCurrentHealthControllerApi, getCardiacRiskAssessment, getFutureHealthRiskAreasControllerApi, getImproveMyHealthDataControllerApi } from "../../constants/apiList";
import { getColorByLabel, mapRiskLevelToValue } from "../../util/Helper";
import { FaHeartPulse } from "react-icons/fa6";
import SymptomsCloudGraphYearly from "../TrendsToWatch/TrendsToWatchGraphs/SymptomsCloudGraphYearly";
const HealthCardReport = () => {
    const data = [
        { color: "#F05E22", label: "kidney failure" },
        { color: "#FAA63B", label: "kidney disease" },
        { color: "#CADB2A", label: "early stage kidney disease" },
        { color: "#009484", label: "Normal" },
    ];
    const [BPTrendingData, setBPTrendingData] = useState();
    const [riskFactorGFR, setRiskFactorGFR] = useState();
    const [lifeStyleData, setLifeStyleData] = useState({});
    const [healthRiskData, sethealthRiskData] = useState({});
    const [cardiacRiskData, setCardiacRiskData] = useState({});
    const [futureHealthRiskAreas, setFutureHealthRiskAreas] = useState([]);
    const [medicalConditionRisk, setMedicalConditionRisk] = useState([]);
    const [improveMyHealthData, setImproveMyHealthData] = useState([]);
    const [yearlyTopSymptoms, setYearlyTopSymptoms] = useState();
    const [familyInfo, setFamilyInfo] = useState(null);
    const [familyRelation, setFamilyRelation] = useState(null);

    const getLifestyleRiskAssessment = async () => {
        await AxiosInstance.get(`${getLifestyleRiskAssessmentApi}`)
            .then((res) => {
                setLifeStyleData(res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    const healthReportCardController = async () => {
        await AxiosInstance.get(`${getCurrentHealthControllerApi}`)
            .then((res) => {
                sethealthRiskData(res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    const cardiacRiskController = async () => {
        await AxiosInstance.get(`${getCardiacRiskAssessment}`)
            .then((res) => {
                setCardiacRiskData(res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    }
    const getFutureHealthRiskAreas = async () => {
        await AxiosInstance.get(`${getFutureHealthRiskAreasControllerApi}`)
            .then((res) => {
                setFutureHealthRiskAreas(res.riskAreas);
                setMedicalConditionRisk(res.medicalConditionRisk);
            })
            .catch((err) => {
                console.log('err: ', err);
            });
    }
    const getImproveMyHealthData = async () => {
        await AxiosInstance.get(`${getImproveMyHealthDataControllerApi}`)
            .then((res) => {
                setImproveMyHealthData(res)
            })
            .catch((err) => {
                console.log('err: ', err);
            });
    }
    const fetchData = async () => {
        try {
            const response = await AxiosInstance.get(getKidneyRiskAssessment);
            setRiskFactorGFR(response);
        } catch (error) {
            console.log('error: ', error);
        }
    };
    const getBloodPressureTrending = async () => {
        await AxiosInstance.get("dailyLog/getBloodPressureTrendingData")
            .then((response) => {
                setBPTrendingData(response);
            })
            .catch((error) => {

            });
    }
    useEffect(() => {
        getBloodPressureTrending();
        fetchData();
        getLifestyleRiskAssessment();
        cardiacRiskController();
        healthReportCardController();
        getFutureHealthRiskAreas();
        getImproveMyHealthData();
    }, []);
    
     useEffect(() => {
       AxiosInstance.get("profile/get")
         .then((response) => {
           setFamilyInfo(response?.profile);
         })
         .catch((error) => {
           console.error("Error:", error);
         });
       AxiosInstance.get("/family/getPatientFamilyProfile")
         .then((response) => {
           setFamilyRelation(response);
         })
         .catch((error) => {
           console.log(error);
         });
     }, []);
    return (
        <div className="body-border">
        {(familyRelation?.relationShipWithUser != "wife" && familyRelation != null) && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
            </h4>
            <br />
          </>
        )}
            <Row>
                <Col>
                    <center>
                        <span className="traffic-light">
                            <span style={{ backgroundColor: 'red' }} className="traffic-light-red"></span>
                            <span style={{ backgroundColor: 'yellow' }} className="traffic-light-yellow"></span>
                            <span style={{ backgroundColor: 'green' }} className="traffic-light-green"></span>
                        </span>
                    </center>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={6}
                    xl={6}
                    md={12}
                    sm={12}
                    lg={12}>
                    <Row className="column-box">
                        <Col xs={12} className="upper-column"><h4 className="text-center  ">How is my Current Health?</h4> </Col>
                        <Col className="left-column"
                            xs={6}
                            xl={6}
                            md={6}>
                            <div className="mt-4">
                                {healthRiskData.medicalConditions && healthRiskData.medicalConditions.length > 0 ? (
                                    <ul>
                                        {healthRiskData.medicalConditions[0].split(';').map((condition, index) => (
                                            <li key={index}><b>{condition}</b></li>
                                        ))}
                                    </ul>
                                ) : (<>
                                    <div className="divForICon"><CiFaceSmile className="smile-icon" /></div>
                                    <div className="mt-4"><h5>No existing medical condition*</h5></div>
                                </>
                                )}
                            </div>
                            <div className="mt-4">*Based on hospital records shared with us and survey completed in the app. </div>
                        </Col>
                        <Col className="right-column"
                            xs={6}
                            xl={6}
                            md={6}>
                            <div className="chart-item-health-report-card">
                                <SymptomsCloudGraphYearly setYearlyTopSymptoms={setYearlyTopSymptoms} />
                            </div>
                            <div><b>In the last 12 months:</b></div>
                            <div className="mt-2">{healthRiskData.sickCount && (<div>* You were sick {healthRiskData.sickCount} times and had to see a doctor</div>)}</div>
                            <div className="mt-2"> {healthRiskData.averageDay && (<div>* {healthRiskData.averageDay} average healthy days</div>)}</div>
                            <div className="mt-2">
                                {healthRiskData.symptoms && healthRiskData.symptoms.length > 0 && (
                                    <p>*
                                        {healthRiskData.symptoms.slice(0, -1).join(', ')}{' '}
                                        {healthRiskData.symptoms.length > 1 && 'and'}{' '}
                                        {healthRiskData.symptoms.slice(-1)} were your major symptoms
                                    </p>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={6}
                    xl={6}
                    md={12}
                    sm={12}
                    lg={12}>
                    <Row className="column-box">
                        <Col xs={12} className="upper-column"> <h4 className="text-center  ">What are my future Health risks areas?</h4></Col>
                        <Col className="left-column"
                            xs={4}
                            xl={4}
                            md={4}
                            sm={4}
                            lg={4}
                        >
                            <div className="mt-4"><h5>Lifestyle Induced Health Risk Score(LIHRS)</h5></div>
                            <div className="mt-4">
                                <div className="meter-wrapper">
                                    <ReactSpeedometer
                                        width={240}
                                        height={175}
                                        minValue={0}
                                        maxValue={120}
                                        needleHeightRatio={0.7}
                                        value={mapRiskLevelToValue(lifeStyleData?.overallLifestyleRiskScore)}
                                        currentValueText="Risk Level"
                                        customSegmentLabels={[
                                            {
                                                text: "Very Low",
                                                position: "OUTSIDE",
                                                color: "#C60176",
                                            },
                                            {
                                                text: "Low",
                                                position: "OUTSIDE",
                                                color: "#C60176",
                                            },
                                            {
                                                text: "Medium",
                                                position: "OUTSIDE",
                                                color: "#C60176",
                                            },
                                            {
                                                text: "Moderate",
                                                position: "OUTSIDE",
                                                color: "#C60176",
                                            },
                                            {
                                                text: "High",
                                                position: "OUTSIDE",
                                                color: "#C60176",
                                            },
                                        ]}
                                        ringWidth={47}
                                        segmentColors={["#008000", "#90EE90", "#CADB2A", "#FFA500", "#ff0000"]}
                                        needleTransitionDuration={3333}
                                        segmentTransitionDuration={2000}
                                        needleTransition="easeElastic"
                                        needleColor={"#F05E22"}
                                        textColor={"#F05E22"}
                                        innerRadius={0}
                                    />
                                </div>
                            </div>
                            <div className="mt-4"></div>
                        </Col>
                        <Col className="center-column"
                            xs={4}
                            xl={4}
                            md={4}
                            sm={4}
                            lg={4}
                        >
                            <div className="mt-4"><h5>Lifestyle Risk areas</h5></div> <br />
                            <div>
                                {futureHealthRiskAreas && futureHealthRiskAreas.length > 0 ? (
                                    <ul>
                                        {futureHealthRiskAreas.map((area) => (
                                            <li key={area.id}>
                                                {Object.entries(area).map(([key, value]) => (
                                                    <p key={key}>
                                                        <strong>{key}:</strong> {value}
                                                    </p>
                                                ))}
                                            </li>
                                        ))}
                                    </ul>
                                ) : ("")}

                            </div>
                        </Col>
                        <Col className="right-column"
                            xs={4}
                            xl={4}
                            md={4}
                            sm={4}
                            lg={4}
                        >
                            <div className="mt-4"><h5>Medical Conditions Risk</h5></div><br />
                            <List>
                                {medicalConditionRisk && medicalConditionRisk.length > 0 ? (
                                    <ul>
                                        {medicalConditionRisk.map((area) => (
                                            <li key={area.id}>
                                                {Object.entries(area).map(([key, value]) => (
                                                    <p key={key}>
                                                        <strong>{key}:</strong>
                                                        <span
                                                            className="risk-level-circle"
                                                            style={{ background: getColorByLabel(value) }}
                                                        ></span>
                                                    </p>
                                                ))}
                                            </li>
                                        ))}
                                    </ul>
                                ) : ""}
                            </List>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={6}
                    xl={6}
                    md={12}
                    sm={12}
                    lg={12}>
                    <Row className="column-box">
                        <Col xs={12} className="upper-column" > <h4 className="text-center  ">My Heart and Kidney Health</h4> </Col>
                        <Col className="left-column"
                            xs={6}
                            xl={6}
                            md={6}>
                            <div className="mt-4">
                                <center><FaHeartPulse className="heartPulsIcon" /></center>
                                <div className="result-head d-flex justify-content-between">
                                    <span className="frsResults">Total Risk Point : </span>
                                    <span>{cardiacRiskData.riskPoint}</span>
                                </div>
                                <div className="result-head d-flex justify-content-between">
                                    <span className="frsResults">10 Year CVD Risk (%) : </span>
                                    <span>{cardiacRiskData.cvdRiskPercentage}</span>
                                </div>
                                <div className="result-head d-flex justify-content-between">
                                    <span className="frsResults">Heart Age : </span>
                                    <span>{cardiacRiskData.heartAge}</span>
                                </div>
                                <div className="result-head d-flex justify-content-between">
                                    <span className="frsResults">Risk Level : </span>
                                    {cardiacRiskData.riskLevel ? (<span>{cardiacRiskData.riskLevel.riskLevel}</span>) : ("-")}
                                </div>
                                <div className="result-head d-flex justify-content-between">
                                    <span className="frsResults">Modified 10 Year CVD risk % : </span>
                                    <span>{cardiacRiskData.modifiedCvdRiskPercentage ? cardiacRiskData.modifiedCvdRiskPercentage : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col className="right-column"
                            xs={6}
                            xl={6}
                            md={6}>
                            <div className="relative-egfr-block mt-3">
                                <ReactSpeedometer
                                    width={250}
                                    height={175}
                                    needleHeightRatio={0.7}
                                    value={riskFactorGFR?.gfr}
                                    currentValueText={`GFR Value : ${riskFactorGFR?.gfr}`}
                                    minValue={0}
                                    maxValue={120}
                                    customSegmentStops={[0, 15, 60, 90, 120]}
                                    segmentColors={["#F05E22", "#FAA63B", "#CADB2A", "#009484"]}
                                    ringWidth={47}
                                    needleTransitionDuration={3333}
                                    needleTransition="easeElastic"
                                    needleColor={"#F05E22"}
                                    textColor={"#F05E22"}
                                    innerRadius={0}
                                    segmentTransitionDuration={2000}
                                />
                                <div className="labels-footer">
                                    {data.map((entry, index) => (
                                        <p key={index}>
                                            <span style={{ backgroundColor: `${entry.color}` }}></span>
                                            {entry.label}
                                        </p>
                                    ))}
                                </div>
                            </div></Col>
                    </Row>
                </Col>
                <Col
                    xs={6}
                    xl={6}
                    md={12}
                    sm={12}
                    lg={12}>
                    <Row className="column-box">
                        <Col xs={12} className="upper-column"> <h4 className="text-center  ">What am I doing to improve my health?</h4></Col>
                        <Col className="left-column">
                            <div className="mt-4"><h5>Digital wallent</h5></div><br />
                            {improveMyHealthData ? (
                                <div>
                                    <p>Prescription Count: {improveMyHealthData.prescriptionCount}</p>
                                    <p>Test Report Count: {improveMyHealthData.testReportCount}</p>
                                    <p>Other Report Count: {improveMyHealthData.otherReportCount}</p>
                                </div>
                            ) : (
                                <p>Loading health data...</p>
                            )}
                        </Col>
                        <Col className="center-column">
                            <div className="mt-4"><h5>Life style Modifications</h5></div>
                            <div>
                                {improveMyHealthData ? (
                                    <div>
                                        <div className="mt-4 d-flex justify-content-between">
                                            <p>Open Tasks: {improveMyHealthData.openTasks}</p>
                                            <p>Closed Tasks: {improveMyHealthData.closedTasks}</p>
                                        </div>
                                        <div className="mt-4"><h5>Working to change :</h5></div><br />
                                        <List>
                                            {improveMyHealthData.workingToChange &&
                                                improveMyHealthData.workingToChange.map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                        </List>
                                    </div>
                                ) : (
                                    <p>Loading health data...</p>
                                )}
                            </div>
                        </Col>
                        <Col className="right-column">
                            <div className="mt-4"><h5>Lifestyle Risks Trend</h5></div>
                            <div className="mt-4">
                                {/* <div>
                                    <h5>My BP Trending</h5>
                                    <LineChart
                                        width={300}
                                        height={300}
                                        data={BPTrendingData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3" />
                                        <XAxis dataKey="date" />
                                        <YAxis domain={[0, 400]} tickCount={2}></YAxis>
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            name="Systolic Blood Pressure AM"
                                            dataKey="systolicBloodPressureAm"
                                            stroke="#82ca9d"
                                        />
                                        <Line
                                            type="monotone"
                                            name="Systolic Blood Pressure PM"
                                            dataKey="systolicBloodPressurePm"
                                            strokeDasharray="5 5"
                                            stroke="#82ca9d"
                                        />
                                        <br></br>
                                        <Line
                                            type="monotone"
                                            name="Diastolic Blood Pressure AM"
                                            dataKey="diastolicBloodPressureAm"
                                            stroke="#8884d8"
                                            activeDot={{ r: 8 }}
                                        />
                                        <Line
                                            type="monotone"
                                            name="Diastolic Blood Pressure PM"
                                            dataKey="diastolicBloodPressurePm"
                                            stroke="#8884d8"
                                            strokeDasharray="5 5"
                                            activeDot={{ r: 8 }}
                                        />
                                    </LineChart>
                                </div> */}
                            </div>
                            <div className="mt-4">Weight</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default HealthCardReport;
