// visit-controller API's
export const saveVisitInformation = `/visit/save`;
export const getAllVisitDateListOfPatient = `/visit/visit-datelist`;
export const getAllVisitInformationOfPatient = `/visit/list`;
export const getLatestVisitInfoOfPatient = `/visit/latest`;

// test-report-controller
export const uploadTestReportToS3 = `/test-report/upload`;
export const getAllTestReportOfPatient = `/test-report/list`;
export const downloadTestReport = `/test-report/download`;

// profile-controller
export const uploadProfilePicToS3 = "/profile/upload-profile-pic";
export const saveProfileInformationOfPatient = "/profile/save";
export const getProfileInformationOfPatient = "/profile/get";
export const generateUPID = "/profile/generate-upid";
export const getProfilePic = "/profile/download-profile-pic";

// prescription-controller
export const getParsedPrescriptionData = "/prescription/parse";
export const downloadPrescription = "/prescription/download/";

// master-labs-controller
export const addSelectedHospitalByPatient = "/master-labs/add-lab";
export const getAllMasterLabs = "/master-hospital";

// lab-controller
export const addNewHospital = "/hospital/add-new-hospital";
export const getPatientAddedHospital = "/hospital/list";
export const deleteHospital = "/hospital/delete/";

// general-controller
export const forgotPassword = "/forgot-password";
export const changePassword = "/change-password";
export const registerUser = "/api/auth/signup";
export const authenticateUser = "/api/auth/signin";
export const validateOtp = "/api/auth/validateotp/{mobile}/{otp}";
export const sendOtp = "/api/auth/sendotp/{mobile}";
export const getUserDetails = "/api/auth/get-user-detail/{userId}";
export const deleteAccount = "/delete/account";

// document-controller
export const uploadDocumentToS3 = "/document/upload";
export const getAllOtherDocumentOfPatient = "/document/list";
export const downloadDocument = "/document/download";

// doctor-controller
export const addExternalDoctor = "/doctor/add-external-doctor";
export const getAllDoctorsAddedByPatient = "/doctor/list";
export const deleteDoctor = "/doctor/delete/{doctorId}";

// subscription-controller
export const getSubscriptionDetailOfPatient = "/subscription/get-detail";

// city-and-state-list-controller
export const getStateList = "/state/list";
export const getMasterLabCityList = "/lab/city/list";
export const getMasterHospitalCityList = "/hospital/city/list";

// web-security-free-api-controller
export const createCheckoutSessionWithoutToken = "ns/payment";
export const getUserDetailsByPatientId = "ns/get-user-detail";
export const getSubscriptionDetailFlag = "ns/get-subscription-flag";

// hms-controller
export const migratePatient = "hms/dm";
export const patientOtpValidate = "hms/validateotp";
export const SendOtpToPatient = "hms/sendotp";
export const searchPatient = "hms/hmsPatient";
export const getSignupUser = "hms/getSignupUser";
export const addTrialSubscription = "hms/addTrialSubscription";

//  report-controller

export const getHospitalVisitYearlyApi = "/report/getHospitalVisitYearly";
export const getHospitalVisitMonthlyApi = "/report/getHospitalVisitMonthly";
export const getHospitalVisitMinTimeApi = "report/getHospitalVisitMinTime";
export const getHospitalVisitMinTimeYearlyApi = "/report/getHospitalVisitMinTimeYearly";
export const getSymptomsMonthly = "/report/getSymptomsMonthly";
export const getMedicineMonthly = "/report/getMedicineMonthly";
export const getSymptomsYearly = "/report/getSymptomsYearly";
export const getMedicineYearly = "/report/getMedicineYearly";

// risk-assessment-controller
export const getKidneyRiskAssessmentCalculator = "/riskAssessment/getKidneyRiskAssessmentCalculator";
export const cardiacRiskAssessmentCalculator = "/riskAssessment/cardiacRiskAssessmentCalculator";
export const getRiskFactor = "/riskAssessment/getRiskFactor";
export const getKidneyRiskAssessment = "/riskAssessment/getKidneyRiskAssessment";
export const getCardiacRiskAssessment = "/riskAssessment/getCardiacRiskAssessment";
export const getLifestyleRiskAssessmentApi = "/riskAssessment/getLifestyleRiskAssessment";
export const getRiskDiseaseMappingApi = "/riskAssessment/getRiskDiseaseMapping";
export const getRiskAssessmentMappingApi = "/riskAssessment/getRiskTrackerResult";
 
///health-report-card
export const getCurrentHealthControllerApi = "/healthReportCardController/getCurrentHealth";
export const getFutureHealthRiskAreasControllerApi = "/healthReportCardController/getFutureHealthRiskAreas";
export const getImproveMyHealthDataControllerApi = "/healthReportCardController/getImproveMyHealthData";

///health-outlook
export const getHealthoutLook = "/report/getHealthOutLook";

//lab data(build package)
export const setBuildPackageData = "/test-labs/test-lab";
export const getBuildPackageData = "/test-labs/get-test-cart";
export const getStripePaymentLink = "/test-lab-order/create-session";
export const getOrderDetail = "/test-labs/get-test-order";
export const saveLaterToggle = "/test-labs/test-saved";
export const getSaveLaterData = "/test-labs/get-saved-test";
export const getAllTestDataApi = "/test-labs/get-all-test";
export const deleteTests = "/test-labs/test-delete";
export const deleteSavedTests = "/test-labs/save-test-delete";
export const moveToCart = "/test-labs/move-to-cart";
export const ordersFeedbackApi = "/test-labs/order-feedback";
export const getOrdersFeedbackApi = "/test-labs/get-order-feedback";
export const getPaymentDetailListApi = "/test-labs/test-payment-detail-list";
export const getPaymentDetailApi = "/test-labs/test-order-payment-detail";
export const getOrderDetailApi = "/test-labs/order-detail";

// Lab side user
export const getLabOrderDetailApi = "/lab-partner/order-list";
export const getLabOrderDetailByIdApi = "/lab-partner/order-detail";
export const uploadPriceList = "/lab-partner/test-price-import";
export const getTestPrice = "lab-partner/get-test-price";
export const getOrderBillingApi = "/lab-partner/order-billing";
export const getPerformanceRating = "/lab-partner/performance";
export const getGeneralInformation = "/lab-partner/get-general-info";
export const labSignIn = "/lab-partner/auth/signin";
export const getAddressInfo = "/lab-partner/get-location-address";
export const getLicenceInfo = "/lab-partner/get-lab-documents";
export const getSubscription = "/subscription/get-detail";
export const createFamilyProfile = "/family";
export const getFamilyInformation = "/family/get-family";