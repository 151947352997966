import ReactWordcloud from "react-wordcloud";

const SymptomList = ({ cumulativeData, symptoms }) => {
  const options = {
    enableTooltip: false,
    deterministic: false,
    fontFamily: "Poppins",
    fontSizes: [15, 40],
    fontStyle: "normal",
    fontWeight: "600",
    padding: 3,
    rotations: 3,
    rotationAngles: [0, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  const sortedData = cumulativeData && Object.entries(cumulativeData).sort((a, b) => b[1] - a[1]);

  const getColorByRank = (rank) => {
    const colors = ["#ff0000", "#ffa500", "#1967D2", "#008000", "#0000ff"];
    return colors[rank % colors.length];
  };

  const wordcloudData =
    cumulativeData &&
    sortedData.map(([text, value], index) => ({
      text,
      value,
      color: getColorByRank(index),
    }));

  const callbacks = {
    getWordColor: (word) => {
      return word.color;
    },
    getWordTooltip: (word) => `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
  };

  return (
    <>
      <div style={{ height: 200, width: "auto !important" }}>
        <ReactWordcloud
          callbacks={callbacks}
          options={options}
          words={wordcloudData}
        />
      </div>
    </>
  );
};

export default SymptomList;
