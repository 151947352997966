import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PatientTestResultModal from "./PatientTestResultModal";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
const PatientReviews = ({ order, onReviewSubmit }) => {
  const [iViewsResultModalOpen, setIsViewResultModalOpen] = useState(false);
  const [communication, setCommunication] = useState();
  const [askFurtherQuestions, setAskFurtherQuestions] = useState("");
  const [questions, setQuestions] = useState([""]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderClosed, setOrderClosed] = useState(false);
  const toggleViewResultModal = () => {
    setIsViewResultModalOpen(!iViewsResultModalOpen);
  };
  const handleRadioChange = (e) => {
    setAskFurtherQuestions(e.target.value);
    setQuestions([""]); // reset the question fields when option changes
    setErrors({});
  };

  const handleAddQuestion = () => {
    if (questions.length < 3) {
      setQuestions([...questions, ""]);
    }
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!askFurtherQuestions) {
      formErrors.askFurtherQuestions = "Please select an option";
    }
    if (askFurtherQuestions === "Yes") {
      questions.forEach((question, index) => {
        if (!question) {
          formErrors[`question${index}`] = "Question is required";
        }
      });
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const userDetails = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userDetails);
      const userType = parsedData.userType;
      const formattedQuestions = questions.map((question) => ({
        question: question,
      }));
      console.log("form questions", questions, formattedQuestions);
      AxiosInstance.post(
        `doctor/sendMassage/${order.orderId}/${userType}`,
        formattedQuestions
      )
        .then((response) => {
          setIsSubmitted(true);
          onReviewSubmit();
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };
  useEffect(() => {
    if (order) {
      AxiosInstance.get(`doctor/getCommunication/${order.orderId}`)
        .then((response) => {
          if (response != null) {
            const updatedCommunication = {
              ...response,
              doctorReview: {
                ...response.doctorReview,
                testRecommended: response.doctorReview.testRecommended
                  ? response.doctorReview.testRecommended.split(",")
                  : [],
              },
            };
            setCommunication(updatedCommunication);
          } else {
            setCommunication(null);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, []);
  const handleOrderClosed = () => {
    AxiosInstance.post(`doctor/order-accept/${order.orderId}`)
      .then((response) => {
        onReviewSubmit();
        setIsSubmitted(true);
        setOrderClosed(true);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  console.log("requested order", order);
  return (
    <>
      {communication ? (
        <>
          {isSubmitted ? (
            orderClosed ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    Order has been Successfully Closed.
                  </Typography>
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    You have successfully submitted your queries to the doctor.
                  </Typography>
                  <div style={{ fontSize: "16px" }}>
                    You can expect a response from the doctor within 24-36
                    hours. Please check back later to see the response to your
                    queries.
                  </div>
                </div>
              </Box>
            )
          ) : (
            <>
              <TableContainer
                style={{ marginTop: "2%", border: "1px solid black" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: " rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>LSH Order ID</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Order Date</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID</b>
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Test Report</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Test Result</b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableBody>
                    <TableRow
                    // onClick={() => handleRowClick(orders, index)}
                    >
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.orderId}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.createdOn}
                      </TableCell>

                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.preventiveTestReportView.lrId}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        // onClick={toggleViewResultModal}
                      >
                        View
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={toggleViewResultModal}
                      >
                        View
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                isOpen={iViewsResultModalOpen}
                toggle={toggleViewResultModal}
                centered
              >
                <ModalHeader
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid lightgrey",
                  }}
                  toggle={toggleViewResultModal}
                >
                  <div>Test Result</div>
                </ModalHeader>
                <ModalBody>
                  <PatientTestResultModal
                    testResultData={order}
                  ></PatientTestResultModal>
                </ModalBody>
              </Modal>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  marginTop: 2,
                  padding: 2,
                  border: "1px solid #ccc",
                  borderRadius: 2,
                }}
              >
                <Box>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ marginBottom: 1 }}
                  >
                    Doctor Review
                  </Typography>
                  <TextField
                    value={communication?.doctorReview.review}
                    multiline
                    rows={4}
                    fullWidth
                    disabled
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-disabled": {
                        backgroundColor: "#d3d3d37a", // Full background color for the input
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black", // Ensures the text is black when disabled
                      },
                    }}
                  />
                </Box>

                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography component="div" variant="h6">
                      Additional Tests Recommended by Doctor:
                    </Typography>
                    {(communication?.doctorReview?.testRecommended?.length ===
                      0 ||
                      !communication?.doctorReview?.testRecommended) && (
                      <Typography
                        variant="h6"
                        sx={{
                          color: "green",
                          fontWeight: "bold",
                          marginLeft: 2,
                        }}
                      >
                        None
                      </Typography>
                    )}
                  </Box>

                  {communication?.doctorReview?.testRecommended?.length > 0 && (
                    <Box>
                      {communication.doctorReview.testRecommended.map(
                        (testField, index) => (
                          <TextField
                            key={index}
                            value={testField}
                            placeholder={`Test ${index + 1}`}
                            sx={{
                              marginBottom: 1,
                              marginRight: 1,
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                                backgroundColor: "#d3d3d37a",
                              },
                            }}
                            disabled
                          />
                        )
                      )}
                    </Box>
                  )}
                </Box>

                {communication.communicationView?.length > 0 && (
                  <Box
                    sx={{
                      padding: "24px",
                      backgroundColor: "#1c1c1c", // Dark background
                      borderRadius: "16px", // Smoother rounded corners
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)", // Slightly darker shadow
                      border: "1px solid #333", // Darker border for structure
                    }}
                  >
                    <h6 style={{ textAlign: "center", color: "white" }}>
                      Queries raised by patient
                    </h6>
                    {communication.communicationView?.map((item, index) => (
                      <Box key={item.id} sx={{ marginBottom: "24px" }}>
                        {/* Question Heading */}
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#ffa726", // Warm orange for headings
                            fontWeight: "bold",
                          }}
                        >
                          {`Question ${index + 1}`}
                        </Typography>
                        {/* Question Text */}
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                            color: "#ffffff", // White text for contrast
                            backgroundColor: "#424242", // Dark gray background for the question
                            padding: "12px",
                            borderRadius: "8px",
                            border: "1px solid #555", // Soft border for better separation
                          }}
                        >
                          {item.question}
                        </Typography>
                        {/* Doctor Response Heading */}
                        <Typography
                          variant="h6"
                          sx={{
                            marginTop: "8px",
                            color: "#80cbc4", // Soft teal for response heading
                            fontWeight: "bold",
                          }}
                        >
                          Doctor Response
                        </Typography>
                        {/* Doctor's Answer */}
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            color: "#e0f2f1", // Light teal for answer text
                            backgroundColor: "#333", // Darker background for the answer
                            padding: "12px",
                            borderRadius: "8px",
                            border: "1px solid #666", // Matching dark border
                          }}
                        >
                          {item.answer}
                        </Typography>
                        <Divider
                          sx={{ marginTop: "16px", borderColor: "#444" }}
                        />{" "}
                        {/* Subtle gray divider */}
                      </Box>
                    ))}
                  </Box>
                )}

                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    {(communication.communicationView == null ||
                      communication.communicationView.length == 0) && (
                      <FormControl
                        component="fieldset"
                        required
                        error={!!errors.additionalTestsRequired}
                      >
                        <FormLabel component="legend">
                          Do you want to ask any further questions to the Doctor
                          regarding the test report and his or her review
                          comments ?
                        </FormLabel>
                        <RadioGroup
                          value={askFurtherQuestions}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        {errors.askFurtherQuestions && (
                          <Typography color="error" variant="caption">
                            {errors.askFurtherQuestions}
                          </Typography>
                        )}
                      </FormControl>
                    )}

                    {askFurtherQuestions === "Yes" &&
                      questions.map((question, index) => (
                        <TextField
                          key={index}
                          value={question}
                          onChange={(e) =>
                            handleQuestionChange(index, e.target.value)
                          }
                          placeholder={`Question ${index + 1}`}
                          required
                          sx={{ marginBottom: 1, marginRight: 1 }}
                          error={!!errors[`question${index}`]}
                          helperText={errors[`question${index}`]}
                        />
                      ))}

                    {askFurtherQuestions === "Yes" && questions.length < 3 && (
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddQuestion}
                        >
                          Add More Question
                        </Button>
                      </Box>
                    )}

                    {(communication.communicationView?.length > 0 ||
                      askFurtherQuestions === "No") && (
                      <Button
                        onClick={handleOrderClosed}
                        variant="contained"
                        color="success"
                        type="button"
                      >
                        Submit
                      </Button>
                    )}
                    {(communication.communicationView == null ||
                      communication.communicationView.length === 0) &&
                      askFurtherQuestions !== "No" && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                        >
                          Submit
                        </Button>
                      )}
                  </Box>
                </form>
              </Box>
            </>
          )}
        </>
      ) : (
        "No Reviews"
      )}
    </>
  );
};

export default PatientReviews;
