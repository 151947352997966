import { Card, CardContent, CardMedia } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import EnvironmentSketchImage from "../../img/EnvironmentSketchImage.jpeg";
import LifeStyleImage from "../../img/LifeStyleImage.jpg";
import PersonalAndFamilyHistory from "../../img/PersonalAndFamilyHistory.jpg";
import InfoModel from "../../shared/simpleModel/InfoModel";
import BiographicalSketch from "./BiographicalSketch";
import LifeStyleSketch from "./LifeStyleSketch";
import "./SilverScreensProfileCards.css";
import SilverStep1New from "./personal-family-history/SilverStep1New";
import Step1 from "./Step1";
import Step2 from "./Step2";
import SilverStep1 from "./SilverStep1";
import ChildrenLifestyle13to21Question from "./ChildrenLifestyle13to21Question";
import ChildrenEnvironment13to21Questions from "./ChildrenEnvironment13to21Questions";
import ChildrenEnvironment3to13Questions from "./ChildrenEnvironment3to13Questions";
import ChildrenLifestyle3to13Question from "./ChildrenLifestyle3to13Question";
import GenderDobModal from "./GenderDobModal";
import Swal from "sweetalert2";

const SilverScreensProfileCards = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [silverActiveStep, setsilveractiveStep] = useState(0);
  const [LifestyleSketchactiveStep, setLifestyleSketchactiveStep] = useState(0);
  const [BiographicalactiveStep, setBiographicalactiveStep] = useState(0);
  const [PersonalAndFamilyHistoryPopup, setPersonalAndFamilyHistoryPopup] =
    useState();
  const [lifestylePopup, setlifestylePopup] = useState();
  const [EnvironmenntSketchPopup, setEnvironmenntSketchPopup] = useState();
  const [uhid, setUhid] = useState("");
  const [personalFamilyHistoryData, setpersonalFamilyHistoryData] = useState();
  const [lifestyleData, setlifestyleData] = useState();
  const [EnvironmenntSketchData, setEnvironmenntSketchData] = useState();
  const [familyInfo, setFamilyInfo] = useState();
  const [isGenderDobModalOpen, setGenderDobModalOpen] = useState(false);
  const handleNext = (uhid) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setUhid(uhid);
  };

  const SilverhandleNext = () => {
    if (personalFamilyHistoryData) {
      setPersonalAndFamilyHistoryPopup(false);
      setsilveractiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setPersonalAndFamilyHistoryPopup((prev) => !prev);
      // handlePersonalNext();
    }
  };

  const SilverhandleBack = () => {
    setsilveractiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const LifeStylehandleNext = () => {
    if (personalFamilyHistoryData) {
      if (lifestyleData) {
        setlifestylePopup(false);
        setLifestyleSketchactiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setlifestylePopup((prev) => !prev);
      }
    } else {
      Swal.fire({
        text: "Please First Filled Your Personal and Family History Survey to proceed with Lifestyle Survey.",
        icon: "info",
      });
    }
  };
  const LifeStylehandleBack = () => {
    setLifestyleSketchactiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const BiographicalhandleNext = () => {
    if (lifestyleData) {
      if (EnvironmenntSketchData) {
        setEnvironmenntSketchPopup(false);
        setBiographicalactiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setEnvironmenntSketchPopup((prev) => !prev);
      }
    } else {
      Swal.fire({
        text: "Please First Filled Your Lifestyle Survey to proceed with Environment Survey.",
        icon: "info",
      });
    }
  };
  const BiographicalhandleBack = () => {
    setBiographicalactiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchPersonalFamilyHistoryData = () => {
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        setpersonalFamilyHistoryData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchlifestyleSketchData = () => {
    AxiosInstance.get("form/lifestyleSketchDiet")
      .then((response) => {
        setlifestyleData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEnvironmentSketchData = () => {
    AxiosInstance.get("form/environmentSketch")
      .then((response) => {
        setEnvironmenntSketchData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPersonalFamilyHistoryData();
    fetchlifestyleSketchData();
    fetchEnvironmentSketchData();
  }, []);

  useEffect(() => {
    if (
      PersonalAndFamilyHistoryPopup === false &&
      personalFamilyHistoryData == null
    ) {
      setsilveractiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [PersonalAndFamilyHistoryPopup]);

  useEffect(() => {
    if (lifestylePopup === false && lifestyleData == null) {
      setLifestyleSketchactiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [lifestylePopup]);

  useEffect(() => {
    if (EnvironmenntSketchPopup === false && EnvironmenntSketchData == null) {
      setBiographicalactiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [EnvironmenntSketchPopup]);
  const getRelationship = () => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        // console.log("responseFamily: ", response);
        // setFamilyInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRelationship();
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        setFamilyInfo(response.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const initialURL = sessionStorage.getItem("initialURL");

  useEffect(() => {
    const isGenderMissing =
      familyInfo?.gender === undefined || familyInfo?.gender === null;
    const isDobMissing =
      familyInfo?.dob === undefined || familyInfo?.dob === null;
    if (initialURL && (isGenderMissing || isDobMissing)) {
      setGenderDobModalOpen(true);
    } else {
      console.log("Conditions not met for opening modal.");
      setGenderDobModalOpen(false);
    }
  }, [initialURL, familyInfo]);

  const handleModalClose = () => {
    setGenderDobModalOpen(false);
  };

  return (
    <>
      {activeStep == 0 &&
        silverActiveStep == 0 &&
        LifestyleSketchactiveStep == 0 &&
        BiographicalactiveStep == 0 && (
          <div style={{ marginBottom: "10px" }}>
            Please click on the below links to fill the Surveys.
          </div>
        )}
      {activeStep == 0 &&
        silverActiveStep == 0 &&
        LifestyleSketchactiveStep == 0 &&
        BiographicalactiveStep == 0 && (
          <Row>
            {/* <Col lg={3}>
              {(relationship != "wife" && relationship != "son" && relationship != "daughter" && relationship != "father" && relationship != "mother") &&
              <Card
              onClick={handleNext}
              className="cardContainer"
            >
              <CardContent>
                <div className="tier-BRONZE">Tier-1 (BRONZE)</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <img
                    style={{ marginRight: "10px" }}
                    alt="Akar icons circle"
                    src="/img/akar-icons-circle-check-fill-6.png"
                  />
                  <span className="substext-wrapper-3 linksContainer">General Information</span>
                </div>
              </CardContent>
            </Card>
            </Col> */}
            <Col lg={4} style={{ marginBottom: "10px" }}>
              <Card className="cardContainer" onClick={SilverhandleNext}>
                <CardMedia
                  sx={{ height: 200 }}
                  image={PersonalAndFamilyHistory}
                  title="green iguana"
                />
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      alt="Akar icons circle"
                      src="/img/akar-icons-circle-check-fill-6.png"
                    />
                    <span className="linksContainer">
                      Personal and Family History
                    </span>
                  </div>
                </CardContent>
              </Card>
            </Col>
            {familyInfo?.age > 3 ||
            familyInfo == null ||
            familyInfo?.age == null ? (
              <>
                <Col lg={4} style={{ marginBottom: "10px" }}>
                  <Card className="cardContainer" onClick={LifeStylehandleNext}>
                    <CardMedia
                      sx={{ height: 200 }}
                      image={LifeStyleImage}
                      title="green iguana"
                    />
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          style={{ marginRight: "10px" }}
                          alt="Akar icons circle"
                          src="/img/akar-icons-circle-check-fill-6.png"
                        />
                        <span className="linksContainer">Lifestyle Sketch</span>
                      </div>
                    </CardContent>
                  </Card>
                </Col>
                <Col lg={4} style={{ marginBottom: "10px" }}>
                  <Card
                    className="cardContainer"
                    onClick={BiographicalhandleNext}
                  >
                    <CardMedia
                      sx={{ height: 200 }}
                      image={EnvironmentSketchImage}
                      title="green iguana"
                    />
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          style={{ marginRight: "10px" }}
                          alt="Akar icons circle"
                          src="/img/akar-icons-circle-check-fill-6.png"
                        />
                        <span className="linksContainer">
                          Environment Sketch
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}

            {/* <Col lg={3}>
              { ((relationship == "son" || relationship == "daughter") &&age <= 2) &&
              <Card className="cardContainer">
                <CardMedia
                  sx={{ height: 140 }}
                  image={LifeStyleImage}
                  title="green iguana"
                />
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      alt="Akar icons circle"
                      src="/img/akar-icons-circle-check-fill-6.png"
                    />
                    <span className="linksContainer" onClick={SilverhandleNext}>
                      Lifestyle Sketch
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      alt="Akar icons circle"
                      src="/img/akar-icons-circle-check-fill-6.png"
                    />
                    <span
                      className="linksContainer"
                      onClick={LifeStylehandleNext}
                    >
                      Lifestyle Sketch
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      alt="Akar icons circle"
                      src="/img/akar-icons-circle-check-fill-6.png"
                    />
                    <span
                      className="linksContainer"
                      onClick={BiographicalhandleNext}
                    >
                      Environment Sketch
                    </span>
                  </div>
                </CardContent>
              </Card>
            </Col> */}
          </Row>
        )}
      {activeStep == 1 && <Step1 handleNext={handleNext} />}
      {activeStep == 2 && (
        <Step2
          // handleNext={handleNext}
          uhid={uhid}
        />
      )}
      {silverActiveStep == 1 && (
        <>
          {/* <SilverStep1New
            onBack={SilverhandleBack}
            handleNext={SilverhandleNext}
          /> */}
          <SilverStep1
            onBack={SilverhandleBack}
            handleNext={SilverhandleNext}
            fetchPersonalFamilyHistoryData={fetchPersonalFamilyHistoryData}
          />
        </>
      )}
      {console.log("fmailyInfo", familyInfo)}
      {LifestyleSketchactiveStep == 1 && (
        <>
          {familyInfo?.age >= 3 && familyInfo?.age < 13 && (
            <ChildrenLifestyle3to13Question onBack={LifeStylehandleBack} />
          )}
          {familyInfo?.age >= 13 && familyInfo?.age < 21 && (
            <ChildrenLifestyle13to21Question onBack={LifeStylehandleBack} />
          )}
          {(familyInfo?.age >= 21 || familyInfo == null) && (
            <LifeStyleSketch
              onBack={LifeStylehandleBack}
              fetchlifestyleSketchData={fetchlifestyleSketchData}
            />
          )}
        </>
      )}
      {BiographicalactiveStep == 1 && (
        <>
          {familyInfo?.age >= 3 && familyInfo?.age < 13 && (
            <ChildrenEnvironment3to13Questions
              onBack={BiographicalhandleBack}
            />
          )}
          {familyInfo?.age >= 13 && familyInfo?.age < 21 && (
            <ChildrenEnvironment13to21Questions
              onBack={BiographicalhandleBack}
            />
          )}
          {(familyInfo?.age >= 21 || familyInfo == null) && (
            <BiographicalSketch onBack={BiographicalhandleBack} />
          )}
        </>
      )}
      <InfoModel
        open={PersonalAndFamilyHistoryPopup}
        setOpen={setPersonalAndFamilyHistoryPopup}
        buttonText="Next"
      >
        <img
          src={PersonalAndFamilyHistory}
          style={{ width: "100%", height: "100%" }}
        ></img>
        <div
          style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}
        >
          You are now going to fill your Personal and Family History form
        </div>
      </InfoModel>
      <InfoModel
        open={lifestylePopup}
        setOpen={setlifestylePopup}
        buttonText="Next"
      >
        <img
          src={LifeStyleImage}
          style={{ width: "100%", height: "100%" }}
        ></img>
        <div
          style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}
        >
          You are now going to fill your LifeStyle Sketch form
        </div>
      </InfoModel>
      <InfoModel
        open={EnvironmenntSketchPopup}
        setOpen={setEnvironmenntSketchPopup}
        buttonText="Next"
      >
        <img
          src={EnvironmentSketchImage}
          style={{ width: "100%", height: "100%" }}
        ></img>
        <div
          style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}
        >
          You are now going to fill your Environmennt Sketch form
        </div>
      </InfoModel>
      <GenderDobModal
        open={isGenderDobModalOpen}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default SilverScreensProfileCards;
