import React, { useEffect, useState } from 'react';
import "./OrderDetail.css";
import { AxiosInstance } from '../../configration/AxiosConfiguration';
import { getOrderDetail } from '../../constants/apiList';
import MUITable from '../../shared/MUITable';
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import Feedback from '../Feedback/Feedback';
import OrderDetailInfo from './OrderDetailInfo';
const OrderDetail = ({ setCurrentTabIndex }) => {
    const [modal, setModal] = useState(false);
    const [modalSecond, setModalSecond] = useState(false);
    const [orderId, setOrderId] = useState('');
    const toggle = () => setModal(!modal);
    const toggleSecond = () => setModalSecond(!modalSecond);
    const columns = [
        { id: "orderId", label: "Order Id", isLink: true },
        { id: "labName", label: "Lab Name", isLink: false },
        { id: "testName", label: "Test Name", isLink: false },
        { id: "testPrice", label: "Test Price (INR)", isLink: false },
        { id: "orderStatus", label: "Order Status", isLink: false },
        { id: "createdOn", label: "Created On", isLink: false },
        { id: "feedback", label: "Feedback", numeric: true, disablePadding: false, isLink: true },
    ];
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [fetchedOrderDetails, setFetchedOrderDetails] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    useEffect(() => {
        const getBuildPackageDate = async () => {
            try {
                const response = await AxiosInstance.get(getOrderDetail);
                setFetchedOrderDetails(response);
            } catch (err) {
                console.log('err: ', err);
            }
        };
        getBuildPackageDate();
    }, []);

    useEffect(() => {
        if (fetchedOrderDetails.length > 0) {
            const updatedOrderDetails = fetchedOrderDetails.map(detail => ({
                ...detail,
                testPrice: `${detail.testPrice}`,
                feedback: "Feedback"
            }));
            setOrderDetail(prevDetails => [...prevDetails, ...updatedOrderDetails]);
        }
    }, [fetchedOrderDetails]);
    useEffect(() => {
        if (selectedRowData && selectedRowData.columnName === "feedback") {
            setModal(!modal)
            // setCurrentTabIndex(4);
            setOrderId(selectedRowData.rowData.orderId);
        }
        if (selectedRowData && selectedRowData.columnName === "orderId") {
            setModalSecond(!modalSecond)
            setOrderId(selectedRowData.rowData.orderId);
            // setCurrentTabIndex(4);
        }
    }, [selectedRowData, setCurrentTabIndex, setOrderId]);
    return (
        <>
            <div>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Submit Your Feedback</ModalHeader>
                    <ModalBody>
                        <Feedback orderId={orderId} setModal={setModal}/>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalSecond} toggle={toggleSecond}>
                    <ModalHeader toggle={toggleSecond}>Order Detail</ModalHeader>
                    <ModalBody>
                        <OrderDetailInfo orderId={orderId} setModalSecond={setModalSecond}/>
                    </ModalBody>
                </Modal>
            </div>
            {orderDetail.length > 0 && (
                <MUITable
                    data={orderDetail}
                    columns={columns}
                    setSelectedRowData={setSelectedRowData}
                />
            )}
        </>
    );
};

export default OrderDetail;