import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import "./LifeStyleRiskAssessment.css";
import RiskScore from "./components/RiskScore";
import RisksDiseaseMap from "./components/RisksDiseaseMap";
import SilverScreensProfileCards from "../profileRegistration/SilverScreensProfileCards";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const LifeStyleRiskAssessment = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      {/* <h4 className="risk-management-heading">Lifestyle Risk Factors Assessment</h4> */}

      <Box sx={{ width: "100%" }}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }} className="MobileViewTabs">
          <Tab
            label="Health Sketch"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          {(familyInfo?.age >= 3 || familyInfo == undefined) && (
            <Tab
              label="Risk Score"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 1 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
          )}
          {(familyInfo?.age >= 3 || familyInfo == undefined) && (
            <Tab
              label="Risks to Medical Condition Mapping"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 2 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
          )}
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 1 }}>
            <SilverScreensProfileCards
              RiskScoreNext={() => {
                setCurrentTabIndex((prev) => prev + 1);
              }}
            />
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <RiskScore
              RiskScoreNext={() => {
                setCurrentTabIndex((prev) => prev + 1);
              }}
            />
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <RisksDiseaseMap
              RiskDiseaseMapNext={() => {
                setCurrentTabIndex((prev) => prev + 1);
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default LifeStyleRiskAssessment;
