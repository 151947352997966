import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
} from "reactstrap";
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { getAddressInfo } from "../../constants/apiList";

const LabAddress = () => {
  const [labAddress, setLabAddress] = useState();
  const [labPhoneNumber, setLabPhoneNumber] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [labPincode, setPinCode] = useState();
  const [weekDayFrom, setWeekDayFrom] = useState();
  const [weekEndFrom, setWeekEndFrom] = useState();
  const [weekDayTo, setWeekDayTo] = useState();
  const [weekEndTo, setWeekEndTo] = useState();
  const [weekCollectionTimeFrom, setWeekCollectionTimeFrom] = useState();
  const [weekCollectionTimeTo, setWeekCollectionTimeTo] = useState();
  const [weekEndCollectionTimeFrom, setWeekEndCollectionTimeFrom] = useState();
  const [weekEndCollectionTimeTo, setWeekEndCollectionTimeTo] = useState();
  const [newAddressRow, setNewAddressRow] = useState();
  const [locPincode, setLabPincode] = useState();
  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);

  const [addressrow, setAddressRow] = useState([
    {
      id: null,
      labPinCode: "",
      labAddress: "",
      labPhoneNumber: "",
      labPinCode: "",
      weekDayFrom: "",
      weekDayTo: "",
      weekEndFrom: "",
      weekEndTo: "",
      locationLabPinCode: {
        locPincode: [""],
        weekCollectionTimeFrom: "",
        weekCollectionTimeTo: "",
        weekEndCollectionTimeFrom: "",
        weekEndCollectionTimeTo: "",
      },
    },
  ]);
  const addAddressRow = () => {
    setAddressRow([
      ...addressrow,
      {
        id: null,
        labPinCode: "",
        labAddress: "",
        labPhoneNumber: "",
        labPinCode: "",
        weekDayFrom: "",
        weekDayTo: "",
        weekEndFrom: "",
        weekEndTo: "",
        locationLabPinCode: {
          locPincode: [""],
          weekCollectionTimeFrom: "",
          weekCollectionTimeTo: "",
          weekEndCollectionTimeFrom: "",
          weekEndCollectionTimeTo: "",
        },
      },
    ]);
  };

  const [pincodeRow, setPincodeRow] = useState([{ locPincode: "" }]);
  const addPincode = (addressIndex) => {
    const newAddressRows = Array.from(addressrow);
    // Ensure locationLabPinCode is always treated as an array
    newAddressRows[addressIndex].locationLabPinCode = newAddressRows[addressIndex].locationLabPinCode || {};
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode = newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode || [];

    // Add a new empty string to serviceAreaPinCode array
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode = [...newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode, ""];
    setAddressRow(newAddressRows);
  };
  const newRows = [...addressrow];
  const handleAddressChange = (index, e) => {
    const newRows = [...addressrow];
    newRows[index][e.target.name] = e.target.value;
    setAddressRow(newRows);
  };
  const handlePincodeChange = (addressIndex, pincodeIndex, e) => {
    const newAddressRows = [...addressrow];
    if (
      newAddressRows[addressIndex]?.locationLabPinCode &&
      Array.isArray(newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode) &&
      newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode.length > pincodeIndex
    ) {
      newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode[pincodeIndex] = e.target.value;
      setAddressRow(newAddressRows);
    }
    const updateAddressRow = [...newAddressRows];
    setNewAddressRow(updateAddressRow);
    const newPincodeRows = [...pincodeRow];
    if (newPincodeRows.length > pincodeIndex) {
      newPincodeRows[pincodeIndex][e.target.name] = e.target.value;
      setPincodeRow(newPincodeRows);
    }
  };
  const handleLabAddress = (event) => {
    setLabAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labAddress: false,
    }));
  };
  const handlelabPhoneNumber = (event) => {
    setLabPhoneNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPhoneNumber: false,
    }));
  };
  const handlePinCode = (event) => {
    setPinCode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPincode: false,
    }));
  };
  const handleWeekDayFrom = (event) => {
    setWeekDayFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekDayFrom: false,
    }));
  };
  const handleWeekDayTo = (event) => {
    setWeekDayTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekDayTo: false,
    }));
  };
  const handleWeekEndFrom = (event) => {
    setWeekEndFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndFrom: false,
    }));
  };

  const handleWeekEndTo = (event) => {
    setWeekEndTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndTo: false,
    }));
  };
  const handleLocPinCode = (event) => {
    setLabPincode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      locPincode: false,
    }));
  };
  const handleWeekCollectionTimeFrom = (event) => {
    setWeekCollectionTimeFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekCollectionTimeFrom: false,
    }));
  };
  const handleWeekCollectionTimeTo = (event) => {
    setWeekCollectionTimeTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekCollectionTimeTo: false,
    }));
  };
  const handleWeekEndCollectionTimeFrom = (event) => {
    setWeekEndCollectionTimeFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndCollectionTimeFrom: false,
    }));
  };
  const handleWeekEndCollectionTimeTo = (event) => {
    setWeekEndCollectionTimeTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndCollectionTimeTo: false,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};
   
    addressrow.forEach((address) => {
      if (address.labAddress == undefined || address.labAddress == "") {
        error.labAddress = true;
      }
      if (address.labPhoneNumber == undefined || address.labPhoneNumber == "") {
        error.labPhoneNumber = true;
      }
      if (address.labPinCode == undefined || address.labPinCode == "") {
        error.labPinCode = true;
      }
      // if (address.weekDayFrom == undefined || address.weekDayFrom == "") {
      //   error.weekDayFrom = true;
      // }
      // if (address.weekDayTo == undefined || address.weekDayTo == "") {
      //   error.weekDayTo = true;
      // }
      // if (address.weekEndFrom == undefined || address.weekEndFrom == "") {
      //   error.weekEndFrom = true;
      // }
      // if (address.weekEndTo == undefined || address.weekEndTo == "") {
      //   error.weekEndTo = true;
      // }
      // if (address.locationLabPinCode.weekCollectionTimeFrom == undefined || address.locationLabPinCode.weekCollectionTimeFrom == "") {
      //   error.weekCollectionTimeFrom = true;
      // }
      // if (address.locationLabPinCode.weekCollectionTimeTo == undefined || address.locationLabPinCode.weekCollectionTimeTo == "") {
      //   error.weekCollectionTimeTo = true;
      // }
      // if (address.locationLabPinCode.weekEndCollectionTimeFrom == undefined || address.locationLabPinCode.weekEndCollectionTimeFrom == "") {
      //   error.weekEndCollectionTimeFrom = true;
      // }
      // if (address.locationLabPinCode.weekEndCollectionTimeTo == undefined || address.locationLabPinCode.weekEndCollectionTimeTo) {
      //   error.weekEndCollectionTimeTo = true;
      // }
    });
    // if (locPincode === undefined || locPincode == "") {
    //   error.locPincode = true;
    // }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      setFormSubmitted(true);
      const formData = newAddressRow ? newAddressRow : newRows;
      const transformedData = formData?.map((item) => ({
        id: item?.id ? item?.id : null,
        labPinCode: item.labPinCode,
        labAddress: item.labAddress,
        labPhoneNumber: item.labPhoneNumber,
        workDayStartTime: item.weekDayFrom,
        workDayEndTime: item.weekDayTo,
        workDayWeekendStartTime: item.weekEndFrom,
        workDayWeekendEndTime: item.weekEndTo,
        locationLabPinCode: {
          serviceAreaPinCode: item.locationLabPinCode?.serviceAreaPinCode.map((loc) => loc),
          workDayStartTime: item.weekCollectionTimeFrom,
          workDayEndTime: item.weekCollectionTimeTo,
          workDayWeekendStartTime: item.weekEndCollectionTimeFrom,
          workDayWeekendEndTime: item.weekEndCollectionTimeTo,
        },
      }));
      AxiosInstance.post("lab-partner/lab-location-address", transformedData)
        .then((response) => {
          setLabAddress("");
          setLabPhoneNumber("");
          setPinCode("");
          setLabPincode("");
          setWeekDayFrom("");
          setWeekDayTo("");
          setWeekEndFrom("");
          setWeekEndTo("");
          setWeekCollectionTimeFrom("");
          setWeekCollectionTimeTo("");
          setWeekEndCollectionTimeFrom("");
          setWeekEndCollectionTimeTo("");
          toast.success("Information submitted successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const GetAddressInfo = async () => {
    await AxiosInstance.get(`${getAddressInfo}`)
      .then((response) => {
        // const lab = response.map((item, index) => item)
        setAddressRow(response);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    GetAddressInfo();
  }, []);
  return (
    <>
      <div style={{ display: "flex" }}>
        <Button color="danger" outline onClick={addAddressRow}>
          Click here to add more Locations
        </Button>
      </div>
      {addressrow &&
        addressrow?.map((row, addressIndex) => (
          <>
            <Form
              style={{
                border: "1px solid lightgrey",
                padding: "15px",
                marginTop: "20px",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                fontSize: "18px",
              }}
            >
              <>
                <Row className="mt-4">
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`labAddress${addressIndex}`}>
                        <b>Location Address</b>
                      </Label>
                      <Input
                        for={`labAddress${addressIndex}`}
                        name="labAddress"
                        placeholder="Enter Location Address"
                        value={row.labAddress}
                        type="text"
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setLabAddress(e.target.value);
                          handleLabAddress(e);
                        }}
                        style={{
                          borderColor: fieldErrors.labAddress ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for={`labPhoneNumber${addressIndex}`}>
                        <b>Phone Number</b>
                      </Label>
                      <Input
                        for={`labPhoneNumber${addressIndex}`}
                        name="labPhoneNumber"
                        placeholder="Enter Location Phone Number"
                        value={row?.labPhoneNumber}
                        type="number"
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setLabPhoneNumber(e.target.value);
                          handlelabPhoneNumber(e);
                        }}
                        style={{
                          borderColor: fieldErrors.labPhoneNumber ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for={`labPinCode${addressIndex}`}>
                        <b>Pin Code</b>
                      </Label>
                      <Input
                        for={`labPinCode${addressIndex}`}
                        name="labPinCode"
                        placeholder="Enter Location PinCode"
                        value={row?.labPinCode}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setPinCode(e.target.value);
                          handlePinCode(e);
                        }}
                        type="number"
                        style={{
                          borderColor: fieldErrors.labPinCode ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Working Timing From</b> (Monday - Friday){" "}
                      </Label>
                      <Input
                        for={`weekDayFrom${addressIndex}`}
                        name="weekDayFrom"
                        value={row?.workDayStartTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekDayFrom(e.target.value);
                          handleWeekDayFrom(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekDayFrom ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Working Timing To</b> (Monday - Friday){" "}
                      </Label>
                      <Input
                        for={`weekDayTo${addressIndex}`}
                        name="weekDayTo"
                        placeholder="Enter PinCode"
                        value={row?.workDayEndTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekDayTo(e.target.value);
                          handleWeekDayTo(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekDayTo ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Working Timing From</b> (Saturday & Sunday)
                      </Label>
                      <Input
                        for={`weekEndFrom${addressIndex}`}
                        name="weekEndFrom"
                        placeholder="Enter PinCode"
                        value={row?.workDayWeekendStartTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekEndFrom(e.target.value);
                          handleWeekEndFrom(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekEndFrom ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Working Timing To</b> (Saturday & Sunday)
                      </Label>
                      <Input
                        for={`weekEndTo${addressIndex}`}
                        name="weekEndTo"
                        placeholder="Enter PinCode"
                        value={row?.workDayWeekendEndTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekEndTo(e.target.value);
                          handleWeekEndTo(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekEndTo ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ width: "60%", marginInline: "auto" }}></hr>
                <Row>
                  <Col md={7} className="mt-3">
                    <FormGroup>
                      <Label for={`LocPincode${addressIndex}`}>
                        <b>List all PIN Codes where this location will provide home collection service</b>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {row?.locationLabPinCode &&
                  Array.isArray(row.locationLabPinCode.serviceAreaPinCode) &&
                  row.locationLabPinCode.serviceAreaPinCode.map((pincode, index) => (
                    <>
                      <Row key={index}>
                        <Col md="4">
                          <FormGroup>
                            <Input
                              id={`locPincode${index}`}
                              name="locPincode"
                              placeholder="Enter Pincode"
                              value={pincode}
                              type="number"
                              onChange={(e) => {
                                handlePincodeChange(addressIndex, index, e);
                                setLabPincode(e.target.value);
                                handleLocPinCode(e);
                              }}
                              style={{
                                borderColor: fieldErrors ? "red" : "lightgrey",
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            {/* <Button color="danger" outline style={{ fontSize: "15px", width: "75%" }} onClick={() => addPincode(addressIndex)}>
                              Add more pincode
                            </Button> */}
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ))}
                {addressrow[addressIndex] &&
                  pincodeRow.map((row, index) => (
                    <>
                      <Row key={index}>
                        {/* <Col md="4">
                          <FormGroup>
                            <Input
                              id={`locPincode${index}`}
                              name="locPincode"
                              placeholder="Enter Pincode"
                              value={row.pincode}
                              type="number"
                              onChange={(e) => {
                                handlePincodeChange(addressIndex, index, e);
                                setLabPincode(e.target.value);
                                handleLocPinCode(e);
                              }}
                              style={{
                                borderColor: fieldErrors.locPincode ? "red" : "lightgrey",
                              }}
                            />
                          </FormGroup>
                        </Col> */}
                        <Col md={2}>
                          <FormGroup>
                            <Button color="danger" outline style={{ fontSize: "15px", width: "75%" }} onClick={() => addPincode(addressIndex)}>
                              Add more pincode
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ))}
                <Row className="mt-5">
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing From</b>
                        <br></br> (Monday - Friday)
                      </Label>
                      <Input
                        for={`weekCollectionTimeFrom${addressIndex}`}
                        name="weekCollectionTimeFrom"
                        placeholder="Enter PinCode"
                        value={row.weekCollectionTimeFrom}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekCollectionTimeFrom(e.target.value);
                          handleWeekCollectionTimeFrom(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekCollectionTimeFrom ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing To</b>
                        <br></br> (Monday - Friday){" "}
                      </Label>
                      <Input
                        for={`weekCollectionTimeTo${addressIndex}`}
                        name="weekCollectionTimeTo"
                        placeholder="Enter PinCode"
                        value={row.locationLabPinCode?.workDayEndTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekCollectionTimeTo(e.target.value);
                          handleWeekCollectionTimeTo(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekCollectionTimeTo ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing From</b>
                        <br></br> (Saturday & Sunday)
                      </Label>
                      <Input
                        for={`weekEndCollectionTimeFrom${addressIndex}`}
                        name="weekEndCollectionTimeFrom"
                        placeholder="Enter PinCode"
                        value={row.locationLabPinCode?.workDayWeekendStartTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekEndCollectionTimeFrom(e.target.value);
                          handleWeekEndCollectionTimeFrom(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekEndCollectionTimeFrom ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing To</b> <br></br>(Saturday & Sunday)
                      </Label>
                      <Input
                        for={`weekEndCollectionTimeTo${addressIndex}`}
                        name="weekEndCollectionTimeTo"
                        placeholder="Enter PinCode"
                        value={row.locationLabPinCode?.workDayWeekendEndTime}
                        onChange={(e) => {
                          handleAddressChange(addressIndex, e);
                          setWeekEndCollectionTimeTo(e.target.value);
                          handleWeekEndCollectionTimeTo(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.weekEndCollectionTimeTo ? "red" : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}></Col>
                  <Col md={3}>
                    <FormGroup></FormGroup>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </>
            </Form>
          </>
        ))}
      <center>
        <Button
          onClick={handleSubmit}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(83, 83, 233)",
          }}
        >
          Save
        </Button>
      </center>
    </>
  );
};
export default LabAddress;
