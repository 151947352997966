import React, { useEffect, useState } from "react";
import "./SubscriptionPackages.css";
import HeaderBeforeLogin from "../HomePageBeforeLogin/HeaderBeforeLogin";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import axios from "axios";
import { Button } from "@mui/material";
import {
  getPaymentCancelBaseUrl,
  getPaymentSuccessBaseUrl,
} from "../../util/utils";
const SubscriptionPackages = () => {
  const [subscriptionPackages, setsubscriptionPackages] = useState();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [payNow, setpayNow] = useState(false);
  const [planId, setplanId] = useState();
  const [paymentRenderLink, setPaymentRenderLink] = useState();

  const handlePackageSelect = (packageIndex) => {
    setSelectedPackage(packageIndex);
    setpayNow(true);
    setplanId(packageIndex);
  };

  const successURL = "https://" + `dev.livingscopehealth.in` + `/dashboard?isUserSubscribed=${true}`;
  const CancelUrl = "https://" + `dev.livingscopehealth.in` + "/homeBeforeLogin";

  // const successURL = "http://" + "localhost:1234" + "/dashboard?isUserSubscribed=true";
  // const CancelUrl = "http://" + "localhost:1234/homeBeforeLogin";

  const createCheckoutSession = () => {
    AxiosInstance.get(`/payment/create-session/${planId}?successURL=${successURL}&cancelURL=${CancelUrl}`)
      .then((response) => {
        setPaymentRenderLink(response);
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}subscription/get-plan-detail`)
      .then((response) => {
        setsubscriptionPackages(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log("subscription", subscriptionPackages);
  return (
    <div className="SubscriptionPageBeforeLogin">
      <HeaderBeforeLogin />
      <div className="subscriptionContainer">
        <h4>Health Packages and Subscription Price</h4>
        <table
          className="simple-table"
          style={{ border: "1px solid black", borderCollapse: "unset" }}
        >
          <thead>
            <tr>
              <th colSpan="5" style={{ backgroundColor: "orange" }}>
                Bronze-Manage Your Health Records with Digital Wallet
              </th>
            </tr>
            <tr>
              <th>Package</th>
              <th>Price (Month)</th>
              <th>Price (Year)</th>
              <th>Note</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionPackages?.map((item, index) => {
              // Determine if the current plan is Bronze, Silver, or Gold
              const currentPlanType = item.planName.split(" ")[0]; // Extract the plan type (e.g., "Tier-1", "Tier-2", "Tier-3")

              // Determine the plan type of the next item (if exists)
              const nextPlanType =
                index < subscriptionPackages.length - 1
                  ? subscriptionPackages[index + 1].planName.split(" ")[0]
                  : null;

              return (
                <React.Fragment key={item.id}>
                  <tr>
                    <td>{item.packageName}</td>
                    <td>{item.priceMonthly}</td>
                    <td>{item.priceYearly}</td>
                    <td>{item.note}</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="radio"
                        name="packageSelection"
                        checked={selectedPackage === item.id}
                        onChange={() => handlePackageSelect(item.id)}
                      />
                    </td>
                  </tr>

                  {/* Add Silver header after the last Bronze plan */}
                  {currentPlanType === "Tier-1" && // Bronze plan
                    nextPlanType !== "Tier-1" && ( // Next plan is not Bronze
                      <tr>
                        <th
                          colSpan="5"
                          style={{
                            backgroundColor: "dodgerblue",
                            color: "white",
                          }}
                        >
                          Silver-Know Your Health Risks Before it is Too Late
                        </th>
                      </tr>
                    )}

                  {/* Add Gold header after the last Silver plan */}
                  {currentPlanType === "Tier-2" && // Silver plan
                    nextPlanType !== "Tier-2" && ( // Next plan is not Silver
                      <tr>
                        <th
                          colSpan="5"
                          style={{
                            backgroundColor: "lightgreen",
                            color: "black",
                          }}
                        >
                          GOLD-Prevent & Protect From Future Medical Conditions
                          and Diseases
                        </th>
                      </tr>
                    )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div
          style={{
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
            backgroundColor: "white",
          }}
        >
          <h5 style={{ paddingTop: "15px", textAlign: "center" }}>
            IMPORTANT NOTES
          </h5>
          <ul style={{ listStyle: "inside", padding: "5px" }}>
            <li>All prices shown above are in Indian Rupees (INR).</li>
            <li>
              Singup for Silver Package and get Bronze package completely FREE
              with it.
            </li>
            <li>
              Signup for our flagship Gold Package and get SILVER AND BRONZE
              package complately FREE with it.
            </li>
            <li>
              Gold Package is available fo signup ONLY after you have used our
              Bronze and Silver package for one month.
            </li>
            <li>
              All the above Health Packages are available as yealy subscription
              only.
            </li>
            <li>
              Children only upto 21 years can be signed up as part of your
              family package. After 21 years, they have to transfer as
              Individual if they want to continue to use our service.
            </li>
          </ul>
          {payNow && (
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                onClick={createCheckoutSession}
                sx={{
                  background: "#5353e9",
                  color: "#fff",
                  minWidth: "180px",
                  "&:hover": { background: "#c60176" },
                }}
              >
                Pay Now
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPackages;
