import { getCenturyWithSuffix } from "../util/utils";
export const formatDateWithMonthName = (dateValue) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateValue);
  return date.toLocaleDateString(undefined, options);
};

export function formatDateMonthNameFirstThreeChar(dateString) {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const day = date.getDate();
  return `${month} ${day}, ${year}`;
}

export function formatDateDMY(dateString) {
  if (!dateString) {
    return "N/A";
  }
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const day = date.getDate();
  return `${day}-${month}-${year}`;
}

export function getTodayDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${date}/${month}/${year}`;
}

const generations = [
  {
    label: "The Great",
    value: "The Great",
    range: [1901, 1924],
  },
  {
    label: "The Silent",
    value: "The Silent",
    range: [1925, 1945],
  },
  {
    label: "The Baby Boomers",
    value: "The Baby Boomers",
    range: [1946, 1964],
  },
  {
    label: "X",
    value: "X",
    range: [1965, 1979],
  },
  { label: "Millennials", value: "Millennials", range: [1980, 1994] },
  {
    label: "Z",
    value: "Z",
    range: [1995, 2012],
  },
  {
    label: "Alpha",
    value: "Alpha",
    range: [2013, 2025],
  },
];

export function calculateAge(dob) {
  const ageGeneration = { age: "", generation: "" };
  const birthDate = new Date(dob);
  const currentDate = new Date();
  const ageDiff = currentDate - birthDate;
  const ageDate = new Date(ageDiff);
  const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
  const selectedYear = birthDate.getUTCFullYear();
  const generation = generations.find((gen) => selectedYear >= gen.range[0] && selectedYear <= gen.range[1]);
  ageGeneration.age = calculatedAge.toString();
  ageGeneration.generation = generation?.value ?? getCenturyWithSuffix(selectedYear);
  return ageGeneration;
}

export function getGFRDescription(gfStage) {
  switch (gfStage) {
    case "G1":
      return "Normal or high";
    case "G2":
      return "Mildly decreased";
    case "G3a":
      return "Mildly to moderately decreased";
    case "G3b":
      return "Moderately to severely decreased";
    case "G4":
      return "Severely decreased";
    case "G5":
      return "Kidney failure";
    default:
      return "Unknown GFR stage";
  }
}
export function getACRDescription(acrCategory) {
  switch (acrCategory) {
    case "A1":
      return "Normal to mildly increased";
    case "A2":
      return "Moderately increased";
    case "A3":
      return "Severely increased";
    default:
      return "Unknown ACR Category";
  }
}

export const getAverageEGFR = (age) => {
  if (age >= 20 && age <= 29) {
    return 116;
  } else if (age >= 30 && age <= 39) {
    return 107;
  } else if (age >= 40 && age <= 49) {
    return 99;
  } else if (age >= 50 && age <= 59) {
    return 93;
  } else if (age >= 60 && age <= 69) {
    return 85;
  } else if (age >= 70) {
    return 75;
  } else {
    return null;
  }
};

export function formatRiskFactor(value) {
  if (value < 0) {
    return `Your kidney is functioning ${Math.abs(Math.round(value))}% lower than the average person in the same age group.`;
  } else {
    return `Your kidney is functioning ${Math.abs(Math.round(value))}% better than the average person in the same age group.`;
  }
}

export function getColorByLabel(label) {
  switch (label) {
    case "Very Low":
      return "#008000";
    case "Low":
      return "#90EE90";
    case "Medium":
      return "#CADB2A";
    case "Moderate":
      return "#FFA500";
    case "High":
      return "#ff0000";
    default:
      return null;
  }
}

export const mapRiskLevelToValue = (level) => {
  switch (level?.toLowerCase()) {
    case "very low":
      return 5;
    case "low":
      return 30;
    case "medium":
      return 60;
    case "moderate":
      return 90;
    case "high":
      return 120;
    default:
      return 0;
  }
};

export const heightWeightRangeObj = [
  {
    height: "4'10",
    weight: [
      { range: "119-142", points: 1 },
      { range: "143-190", points: 2 },
      { range: "191-1000", points: 3 },
    ],
  },
  {
    height: "4'11",
    weight: [
      { range: "124-147", points: 1 },
      { range: "148-197", points: 2 },
      { range: "198-1000", points: 3 },
    ],
  },
  {
    height: "5'0",
    weight: [
      { range: "128-152", points: 1 },
      { range: "153-203", points: 2 },
      { range: "204-1000", points: 3 },
    ],
  },
  {
    height: "5'1",
    weight: [
      { range: "132-157", points: 1 },
      { range: "158-210", points: 2 },
      { range: "211-1000", points: 3 },
    ],
  },
  {
    height: "5'2",
    weight: [
      { range: "136-163", points: 1 },
      { range: "164-217", points: 2 },
      { range: "218-1000", points: 3 },
    ],
  },
  {
    height: "5'3",
    weight: [
      { range: "141-168", points: 1 },
      { range: "169-224", points: 2 },
      { range: "225-1000", points: 3 },
    ],
  },
  {
    height: "5'4",
    weight: [
      { range: "145-173", points: 1 },
      { range: "174-231", points: 2 },
      { range: "232-1000", points: 3 },
    ],
  },
  {
    height: "5'5",
    weight: [
      { range: "150-179", points: 1 },
      { range: "180-239", points: 2 },
      { range: "240-1000", points: 3 },
    ],
  },
  {
    height: "5'6",
    weight: [
      { range: "155-185", points: 1 },
      { range: "186-246", points: 2 },
      { range: "247-1000", points: 3 },
    ],
  },
  {
    height: "5'7",
    weight: [
      { range: "159-190", points: 1 },
      { range: "191-254", points: 2 },
      { range: "255-1000", points: 3 },
    ],
  },
  {
    height: "5'8",
    weight: [
      { range: "164-196", points: 1 },
      { range: "197-261", points: 2 },
      { range: "262-1000", points: 3 },
    ],
  },
  {
    height: "5'9",
    weight: [
      { range: "169-202", points: 1 },
      { range: "203-269", points: 2 },
      { range: "270-1000", points: 3 },
    ],
  },
  {
    height: "5'10",
    weight: [
      { range: "174-208", points: 1 },
      { range: "209-277", points: 2 },
      { range: "278-1000", points: 3 },
    ],
  },
  {
    height: "5'11",
    weight: [
      { range: "179-214", points: 1 },
      { range: "215-285", points: 2 },
      { range: "286-1000", points: 3 },
    ],
  },
  {
    height: "6'0",
    weight: [
      { range: "184-220", points: 1 },
      { range: "221-293", points: 2 },
      { range: "294-1000", points: 3 },
    ],
  },
  {
    height: "6'1",
    weight: [
      { range: "189-226", points: 1 },
      { range: "227-301", points: 2 },
      { range: "302-1000", points: 3 },
    ],
  },
  {
    height: "6'2",
    weight: [
      { range: "194-232", points: 1 },
      { range: "233-310", points: 2 },
      { range: "311-1000", points: 3 },
    ],
  },
  {
    height: "6'3",
    weight: [
      { range: "200-239", points: 1 },
      { range: "240-318", points: 2 },
      { range: "319-1000", points: 3 },
    ],
  },
  {
    height: "6'4",
    weight: [
      { range: "205-245", points: 1 },
      { range: "246-327", points: 2 },
      { range: "325-1000", points: 3 },
    ],
  },
];
