import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  getLabOrderDetailApi,
  getLabOrderDetailByIdApi,
} from "../../constants/apiList";
import AppLogo from "../../../static/img/AppLogoWithName.png";
import NoOrderImage from "./Images/no-order.jpeg";

const DieticianOrders = () => {
  const [rowData, setRowData] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const toggle = () => setModal(!modal);

  const getLabOrderDetail = async () => {
    await AxiosInstance.get(`${getLabOrderDetailApi}`)
      .then((response) => {
        if (response != null) {
          setRowData(response);
        } else {
          setRowData([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  const viewOrderDetail = (id) => {
    setOrderId(id);
    setModal(!modal);
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Issues") {
      Swal.fire({
        html: `
        <div>
          <p>Mention what issue you are facing to execute this home collection service.</p>
          <input type="text" id="swal-input1" class="swal2-input">
          <p><b>Note: </b>Please call our Account Manager for resolving if needed.</p>
        </div>
      `,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
      });
    }
  };
//   useEffect(() => {
//     getLabOrderDetail();
//     const today = new Date().toISOString().substr(0, 10);
//     setSelectedDate(today);
//   }, []);
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const filteredOrders = rowData.filter((order) => order.date === selectedDate);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        <div
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            display: "flex",
            alignItems: "center",
            width: "25%",
          }}
        >
          <b>Date :</b>
          <span style={{ marginLeft: "10px" }}>
            <input
              type="date"
              className="form-control"
              id="dateInput"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </span>
        </div>
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>
          This screen will show you your nutrition consulting orders from
          Uncurl: Health
        </b>
      </p>
      {/* <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader style={{ backgroundColor: "black", color: "white", border: "1px solid lightgrey" }} toggle={toggle}>
            <img src={AppLogo} alt="Lab" />
          </ModalHeader>
          <ModalBody>
            <OrderDetailModel orderId={orderId} />
          </ModalBody>
        </Modal>
      </div>
      {filteredOrders.length === 0 ? (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{marginBottom:"2%", marginLeft:"17px"}}>No Orders Available!</h4>
              <img src={NoOrderImage} alt="Lab" style={{width:"25%"}}/>
            </span>
          </div>
        </>
      ) : (
        filteredOrders.map((order) => (
        <>
          <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
            <Table key={order.orderId}>
              <TableRow>
                <TableCell style={{ backgroundColor: " rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>Date :</b> {order.date}
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: "rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>Time :</b> 10:00 AM
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: " rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>LSH Order ID :</b> {order.orderId}
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: "rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>Patient UHID :</b> {order.patientId}
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: " rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <b>LRID :</b> {order.testDetail.some((test) => test.lrid) ? order.testDetail.map((test) => (test.lrid ? test.lrid : "Not Available")).join(", ") : "Not Available"}
            </TableCell>
          </TableRow>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Name</TableCell>
                  <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Cell</TableCell>
                  <TableCell colSpan={2} style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", textAlign: "center", fontSize: "20px" }}>
                Address
              </TableCell>
              <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Test(s)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "1px solid lightgrey", fontSize: "20px" }}>{order.patientDetail.patientName}</TableCell>
              <TableCell style={{ border: "1px solid lightgrey", fontSize: "20px" }}>{order.patientDetail.patientContactNumber}</TableCell>
                  <TableCell colSpan={2} style={{ border: "1px solid lightgrey", textAlign: "center", fontSize: "20px" }}>
                    {order.patientDetail.address.addressLine1 +
                      ", " +
                      order.patientDetail.address.city +
                      " " +
                      order.patientDetail.address.zipcode +
                      "( " +
                      order.patientDetail.address.state +
                      ", " +
                      order.patientDetail.address.country +
                      ")"}
                  </TableCell>
                  <TableCell style={{ border: "1px solid lightgrey", fontSize: "20px" }}> {order.testDetail.map((test) => test.testName).join(", ")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", textAlign: "center", fontSize: "20px", border: "1px solid lightgrey" }}>
                Order Copy
              </TableCell>
              <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Order Status</TableCell>
            <TableCell colSpan={2} style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", textAlign: "center", border: "1px solid lightgrey", fontSize: "20px" }}>
                Upload Report
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} style={{ border: "1px solid lightgrey", textAlign: "center", fontSize: "20px" }}>
                <a href="#" onClick={() => viewOrderDetail(order.orderId)}>
                      View Order Copy
                    </a>
              </TableCell>
              <TableCell style={{ border: "1px solid lightgrey" }}>
                <select className="form-control form-control-sm" onChange={handleSelectChange}>
                      <option selected disabled>
                        Order Generated
                      </option>
                  <option>Accepted</option>
                  <option>Sample Collected</option>
                  <option>Completed</option>
                  <option>Issues</option>
                </select>
              </TableCell>
              <TableCell colSpan={2} style={{ border: "1px solid lightgrey" }}>
                <center>
                  <input type="file" />
                </center>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
     </>
        ))
      )} */}
    </>
  );
};
export default DieticianOrders;
