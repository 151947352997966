import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Box from "@mui/material/Box";
import MUITable from "../../shared/MUITable";
import Spinner from "../../shared/Spinner";
import DoctorReview from "./DoctorReview";
import TestResultModal from "./TestResultModal";
import "./DoctorScreens.css";
const columns = [
  {
    id: "dateRecommended",
    label: "Date",
    isDate: true,
  },
  {
    id: "medicalCondition",
    label: "Medical Condition",
  },
  {
    id: "lrid",
    label: "LRID",
  },
  {
    id: "preventiveTest",
    label: "Preventive Test",
  },
  {
    id: "testName",
    label: "Test Name",
  },
  {
    id: "filePath",
    label: "Test Report",
    isLink: true,
  },
  {
    id: "viewTestDoctorResults",
    label: "Test Result",
    isLink: true,
  },
  {
    id: "customColumn",
    label: "Review Status",
  },
  {
    id: "finalStatus",
    label: "Final Status",
  },
];
const ConsultantPatients = ({ setcurrentTabIndex, setselectedRowData }) => {
  const [filterDate, setFilterDate] = useState("");
  const [testResult, setTestResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [recordsListData, setRecordsListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const DoctorReviewToggle = () => setIsReviewFormOpen(!isReviewFormOpen);
  const [isTestResultOpen, setIsTestResultOpen] = useState(false);
  const TestResultToggle = () => setIsTestResultOpen(!isTestResultOpen);
  const [isDummyData, setIsDummyData] = useState(false);
  const [lrid, setLrid] = useState("");
  const [fileName, setFileName] = useState(null);
  const [allergies, setAllergies] = useState([]);

  useEffect(() => {
    getTestRecordsList();
    AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        const allergiesArray = JSON.parse(response.allergies);
        setAllergies(allergiesArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let checkLrid = [];
  let updateStatus;
  const getTestRecordsList = async () => {
    AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
      .then((response) => {
        checkLrid = response.map((obj) => obj.lrid);
      })
      .catch((error) => {
        console.log(error);
      });
    AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices").then(
      (response) => {
        let check = response.map((obj) => obj.lrid);
      }
    );
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        "riskAssessment/getLifestylePreventiveCareServices"
      );
      const responseData = response;
      let check = response.map((obj) => obj.lrid);
      updateStatus = check.filter((id) => checkLrid.includes(id))
        ? "Complete"
        : "Incomplete";
      const testReportList = responseData?.map((obj) => {
        const isComplete = check.some((id) => checkLrid.includes(obj.lrid));
        const taskStatus = isComplete ? "Complete" : "Incomplete";
        const setLridNew = obj.lrid;
        setLrid(setLridNew);
        return {
          ...obj,
          dateRecommended: obj.dateRecommended,
          medicalCondition: obj.medicalCondition,
          lrid: obj.lrid,
          preventiveTest: obj.preventiveTest,
          attachReport: "View Report",
          testName: obj.bookForTest,
          viewTestDoctorResults: "View",
          //    attachReport: (
          //      <div>
          //        <label htmlFor="fileUpload" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
          //          View Report
          //        </label>
          //        {isLoading == true ? (
          //          <div>
          //            <Spinner />
          //            <br />
          //            <p>Please wait your prescription is uploading</p>
          //          </div>
          //        ) : (
          //          ""
          //        )}
          //      </div>
          //    ),
          //    taskStatus: taskStatus,
        };
      });
      setRecordsListData(testReportList);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching test records:", error);
      setIsLoading(false);
    }
  };
  const handleFileChange = async (event, selectedRowData) => {
    event.preventDefault();
    const file = event.target.files[0];
    const LRID = selectedRowData.rowData.lrid;
    setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("lrid", LRID);
      setLoading(true);
      AxiosInstance.post("/test-report/uploadPreventiveReport", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag(true);
          setIsOpen(false);
          setLoading(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An error occurred while uploading the file.");
          setLoading(false);
        });
    }
  };
  // };
  const handleRowSelection = (rowData) => {
    setSelectedRowData(rowData);
    // setselectedRowData(rowData); // Pass selected row data to parent
  };
  const onInputClick = (event) => {};
  console.log("preventiveDta", recordsListData, selectedRowData);
  const testReviewHandler = (row) => {
    // setSelectedReport(row);
    setIsReviewFormOpen(true);
  };

  useEffect(() => {
    if (
      !!selectedRowData &&
      selectedRowData.columnName === "prescriptionLink"
    ) {
      //   downloadPrescription(selectedRowData.rowData.prescriptionLink);
    } else if (!!selectedRowData && selectedRowData.columnName === "link") {
      //   downloadReport(selectedRowData.rowData.link);
    } else if (
      !!selectedRowData &&
      selectedRowData.columnName === "customColumn"
    ) {
      testReviewHandler(selectedRowData.rowData);
    } else if (
      !!selectedRowData &&
      selectedRowData.columnName === "viewTestDoctorResults"
    ) {
      setIsTestResultOpen(true);
    }
  }, [selectedRowData]);
  const demoPatient = [
    {
      id: 142,
      recommendation:
        "Please consult your doctor and discuss about your child's risk for CVD considering the family histoy and other Lifestyle risk factos and take appropriate measures as suggested by you doctor.",
      importance:
        "Consideirng age, your child is not at risk for CVD, Howevver it is still important to further assess and validate the Lifestyle risk assessment findings that suggests that your child could be at risk for CVD at a later stage in life. ",
      whyYou:
        "A family history of CVD and several other Lifestyle risk factors suggests your child is at moderate risk for CVD. An early intervention could substantially reduce future risk.",
      preventiveTest: "CVD Risk Assessment (Early Risk Detection)",
      medicalCondition: "CVD-Early",
      lrid: "CVD-02-02",
      patientId: 334,
      dateRecommended: "2024-05-27",
      intensity: "Medium",
      bookForTest: null,
      filePath: "902/corporateReport/DILIP_VERMA.pdf",
      attachReport: "View Report",
      viewTestDoctorResults: "View",
      finalStatus: "Open",
    },
  ];
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        {allergies &&
          allergies?.map((allergy, index) => (
            <div key={index} style={{ animationDelay: `${index * 0.5}s` }}>
              <span
                style={{
                  color: "red",
                  display: "inline-block",
                  animation: "moveLeftRight 15s infinite",
                }}
              >
                <b>Note :</b> Patient is having Allergy from{" "}
                {allergy.MedicineOrFood}, reaction is {allergy.Reaction} and
                effect is {allergy.effect}.
              </span>
            </div>
          ))}
      </div>

      <Box sx={{ width: "100%" }}>
        <Row style={{ alignItems: "end" }}>
          <Col md={8}>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="fileUpload"
                className="file-upload-input"
                name="file"
                onChange={(event) => handleFileChange(event, selectedRowData)}
                onClick={(event) => onInputClick(selectedRowData)}
              />
              {isLoading == true ? (
                <div>
                  <Spinner />
                  <br />
                  <p>Please wait your prescription is uploading</p>
                </div>
              ) : (
                <>Attach</>
              )}
            </div>
          </Col>
          <Col md={4} className="upload-button"></Col>
        </Row>
      </Box>
      {!!demoPatient && (
        <MUITable
          data={demoPatient}
          columns={columns}
          isLoading={isLoading}
          filterDate={filterDate}
          setSelectedRowData={handleRowSelection}
          setcurrentTabIndex={setcurrentTabIndex}
        />
      )}
      <div>
        <Modal
          isOpen={isReviewFormOpen}
          toggle={DoctorReviewToggle}
          centered
          modalClassName="doctorPatient-modal"
        >
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={DoctorReviewToggle}
          >
            <div>Doctor-Patient Chat Session</div>
          </ModalHeader>
          <ModalBody>
            <DoctorReview
              //   onRefresh={getTestRecordsList}
              //   selectedReport={selectedReport}
              setIsReviewFormOpen={setIsReviewFormOpen}
              isDummyData={isDummyData}
            />
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={isTestResultOpen} toggle={TestResultToggle} centered>
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={TestResultToggle}
          >
            <div>Test Result</div>
          </ModalHeader>
          <ModalBody>
            <TestResultModal></TestResultModal>
          </ModalBody>
        </Modal>
      </div>
      {/* {isReviewFormOpen && (
        <DoctorReview
          //   onRefresh={getTestRecordsList}
          //   selectedReport={selectedReport}
          setIsReviewFormOpen={setIsReviewFormOpen}
          isDummyData={isDummyData}
        />
      )} */}
    </>
  );
};

export default ConsultantPatients;
