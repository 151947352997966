import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Form, FormGroup, FormFeedback,FormControlLabel, FormText, Label, Input, Row, Col, Button, FormLabel, RadioGroup, Radio} from "reactstrap";
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { LinkedCamera } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GeneralInfo from "./generalInfo";
import LabAddress from "./labAddress";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const GenralInfoPage = () => {
     const [currentTabIndex, setCurrentTabIndex] = useState(0);

     const handleTabChange = (e, tabIndex) => {
       console.log(tabIndex);
       setCurrentTabIndex(tabIndex);
     };
     return (
       <>
         <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
           <Tab
             label="Main Office"
             sx={{
               textTransform: "capitalize",
               fontWeight: 600,
               fontSize: "large",
               color: currentTabIndex === 0 ? "white" : "black",
               "&.Mui-selected": {
                 color: "white",
               },
             }}
           />
           <Tab
             label="Location Addresses"
             sx={{
               textTransform: "capitalize",
               fontWeight: 600,
               fontSize: "large",
               color: currentTabIndex === 1 ? "white" : "black",
               "&.Mui-selected": {
                 color: "white",
               },
             }}
           />
         </Tabs>
         {currentTabIndex === 0 && (
           <Box sx={{ p: 3 }}>
             <GeneralInfo handleTabChange={handleTabChange}></GeneralInfo>
           </Box>
         )}
         {currentTabIndex === 1 && (
           <Box sx={{ p: 3 }}>
             <LabAddress></LabAddress>
           </Box>
         )}
       </>
     );
};
export default GenralInfoPage