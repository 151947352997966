import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../LifeStyleRiskAssessment.css";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { NavLink, useLocation } from "react-router-dom";

const PreventiveCareServices = ({
  PreventiveCareRecommendationNext,
  FullRecommendationNext,
}) => {
  const [preventiveCare, setPreventiveCare] = useState([]);
  const [familyInfo, setFamilyInfo] = useState(null);

  useEffect(() => {
    AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices")
      .then((response) => {
        console.log("preventive", response);
        setPreventiveCare(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  const handlePreventiveTest = (lrid) => {
    const LRID = lrid;
    AxiosInstance.post(`/test-labs/create-preventive-cart/Lipid%20Profile/${LRID}`)
      .then((response) => {
        console.log("preventive", response);
        setPreventiveCare(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }
    useEffect(() => {
      AxiosInstance.get("profile/get")
        .then((response) => {
          setFamilyInfo(response?.profile);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);

  return (
    <>
      {preventiveCare.length > 0 && (
        <div className="">
          {preventiveCare.map((item, index) => (
            <>
              <table class="table table-bordered" style={{ border: "2px solid black" }}>
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> {item.medicalCondition}
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> {item.dateRecommended}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> {item.preventiveTest}
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> {item.lrid}
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}> {item.importance}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.whyYou}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.recommendation}</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Book for Test - "Lipid Profile"</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Our lab Partner, "XYZ" provides medical tests and diagnostic in your area and can provide you with home collection services.You get <b>20% - 30% discount </b>when you book for
                      lab tests form out Lab Partners through our app. Click below to book for the test.
                      <NavLink to={"/lab-services?page=testcart"}>
                        <center>
                          <button type="button" className="btn btn-success" onClick={() => handlePreventiveTest(item.lrid)}>
                            Book
                          </button>
                        </center>
                      </NavLink>
                    </th>
                  </tr>
                    </>
                  )}
                </tbody>
              </table>
              <br></br>
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default PreventiveCareServices;
